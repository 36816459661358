import { axiosGET, axiosPOST, axiosAuthPOST } from './Setup';
import axios from 'axios';
import { CategoryModel } from '../models';
import { toast } from 'react-toastify';

const baseURL = 'categories';

export class CategoryService {

    public async findCategories(keyword: string): Promise<CategoryModel[]> {
        let url = baseURL + '/find';
        let params = { name: keyword }
        const response = await axiosGET(url, params);
        // const parsed = JSON.parse(response.data);
        const categories: CategoryModel[] = JSON.parse(response.data);
        // parsed.forEach(function (element: StoreModel) {
        //     stores.push(element);
        // });
        // stores.map(function (value, i ) {
        // })
        return categories;
    }

    public async getCategories(): Promise<CategoryModel[]> {
        let url = baseURL;
        // const stores = new Array();
        const response = await axiosGET(url);
        const categories: CategoryModel[] = JSON.parse(response.data);
        return categories;
    }

    public async getCategoriesByStoreName(keyword: string): Promise<CategoryModel[]> {
        try {
            let url = baseURL + '/store';
            let params = { name: keyword }
            const response = await axiosGET(url, params);
            const categories: CategoryModel[] = JSON.parse(response.data);
            return categories;
        } catch (ex) {
            toast.error(ex.message);
            throw new Error(ex.message);
        }

    }

    public async createCategory(category: CategoryModel, token: string) {
        let url = baseURL;
        const response = await axiosAuthPOST(url, category, token);

        return response;
    }
}
import React from 'react';
import EW1 from "../images/EW1.png";

export default function StaticHeader() {
    return (
        <div className="overlay">
          <div className="overlay-content">
            <div
              className="ui seven column stackable middle aligned grid"
              style={{ padding: "0% 3%", color: "white" }}
            >
              <div className="column left aligned">
               <img src={EW1} className="header-logo" alt="logo" />
              </div>
              <div className="column right aligned text-center">
                <a className="white-link" href="#nosotros_section">
                  <h3> NOSOTROS </h3>{" "}
                </a>
              </div>
              <div className="column right aligned text-center">
                <a className="white-link" href="#servicios">
                  <h3> SERVICIOS </h3>
                </a>
              </div>
              <div className="column right aligned text-center">
                <a className="white-link" href="#proyectos">
                  <h3> PROYECTOS </h3>
                </a>
              </div>
              <div className="column right aligned text-center">
                <a className="white-link" href="#socials">
                  <h3> INMOBILIARIA </h3>
                </a>
              </div>
              <div className="column aligned text-center">
                <a className="white-link" href="#socials">
                  <h3> BLOG </h3>
                </a>
              </div>
              <div className="column left aligned text-center">
                <a className="white-link" href="#footer">
                  <h3> CONTÁCTANOS </h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      );
}
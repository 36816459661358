import React from "react";
import { UserModel } from "../models";
interface IImageListProps {
  users: Array<UserModel>;
  date: Date;
}
class ImageList extends React.Component {
  constructor(props: IImageListProps) {
    super(props);
    this.state = { users: {}, date: new Date() };
  }
  render() {
    // const images = this.state.users.map((result) => {
    //   return <div className="ui segment"> Name </div>;
    // });
    return (
      <div className="ui segment row">
        <div className="col-sm-4"></div>
        <div className="col-sm-8">
          <label> Nombre: </label>
          <label> </label>
        </div>
      </div>
    );
  }
}

export default ImageList;

import React from 'react';
import { userService } from '../service';

class SearchBar extends React.Component {

    async onInputChange(event: any) {
        let keyword = event.target.value;
        // let response = await userService.findUsers(keyword)
    }
    render() {
        return (<div className="ui segment"> 
            <form className="ui form">
                <div className="field">
                <label> Buscar: </label>
                <input onChange={this.onInputChange} type="text" />
                </div>
         
          </form>
        </div>
        );
    }
}

export default SearchBar;
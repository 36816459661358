import { CategoryModel, ProductModel } from "../../models";
import {
  Products,
  CREATE_PRODUCT,
  GET_PRODUCTS,
  FIND_PRODUCTS,
  ProductActionTypes,
  UPDATE_PRODUCT,
  UPLOAD_PRODUCT_IMAGE,
  GET_PRODUCT_BY_ID,
  GET_PRODUCT_BY_ID_FAILURE,
  GET_PRODUCT_BY_ID_SUCCESS,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  UPLOAD_PRODUCT_IMAGE_SUCCESS,
  UPLOAD_PRODUCT_IMAGE_FAILURE,
} from "./types";
import { arrayOf } from "prop-types";

const INITIAL_STATE: Products = {
  products: new Array(),
  product: new ProductModel(),
  success: false,
  loading: false,
  image: '',
  imageSuccess: false,
  imageLoading: false,
};

export function productReducer(
  state = INITIAL_STATE,
  action: ProductActionTypes
): Products {
  switch (action.type) {
    case CREATE_PRODUCT:
      return {
        ...state,
        product: action.payload,
        success: true,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        products: action.payload,
      }
    case GET_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        products: new Array(),
      }
    case FIND_PRODUCTS:
      return {
        ...state,
        products: action.payload,
        success: true,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        product: action.payload,
        success: true,
      };
    case UPLOAD_PRODUCT_IMAGE:
      return {
        ...state,
        imageLoading: true,
      };
    case UPLOAD_PRODUCT_IMAGE_SUCCESS:
      console.log('Success: ', action.payload)
      return {
        ...state,
        imageLoading: false,
        image: action.payload,
        imageSuccess: true,
      }
    case UPLOAD_PRODUCT_IMAGE_FAILURE:
      return {
        ...state,
        imageLoading: false,
        image: '',
        imageSuccess: true,
      }
    case GET_PRODUCT_BY_ID:
      return {
        ...state,
        // product: action.payload,
        loading: true,
      };
    case GET_PRODUCT_BY_ID_SUCCESS:
      return {
        ...state,
        product: action.payload,
        loading: false,
        success: true,
      };
    case GET_PRODUCT_BY_ID_FAILURE:
      return {
        ...state,
        product: new ProductModel(),
        loading: false,
        success: false,
      };
    default:
      return state;
  }
}

import { ShoppingCartModel } from '../../models'

export const ADD_TO_CART = 'ADD_TO_CART'
export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS'
export const ADD_TO_CART_FAILURE = 'ADD_TO_CART_FAILURE'

export const GET_CART = 'GET_CART'
export const GET_CART_SUCCESS = 'GET_CART_SUCCESS'
export const GET_CART_FAILURE = 'GET_CART_FAILURE'

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'
export const REMOVE_FROM_CART_SUCCESS = 'REMOVE_FROM_CART_SUCCESS'
export const REMOVE_FROM_CART_FAILURE = 'REMOVE_FROM_CART_FAILURE'

export interface ShoppingCart {
    shoppingCart: ShoppingCartModel[],
    success: boolean,
    loading: boolean,
}

interface AddToCart {
    type: typeof ADD_TO_CART,
}

interface AddToCartFailure {
    type: typeof ADD_TO_CART_FAILURE,
}

interface AddToCartSuccess {
    type: typeof ADD_TO_CART_SUCCESS,
    payload: ShoppingCartModel[],
}

interface GetCart {
    type: typeof GET_CART
}

interface GetCartFailure {
    type: typeof GET_CART_FAILURE
}

interface GetCartSuccess {
    type: typeof GET_CART_SUCCESS,
    payload: ShoppingCartModel[],
}

interface RemoveFromCart {
    type: typeof REMOVE_FROM_CART
}

interface RemoveFromCartFailure {
    type: typeof REMOVE_FROM_CART_FAILURE
}

interface RemoveFromCartSuccess {
    type: typeof REMOVE_FROM_CART_SUCCESS,
    payload: ShoppingCartModel[],
}

export type ShoppingCartActionTypes = 
AddToCart | AddToCartSuccess | AddToCartFailure 
| GetCart |  GetCartSuccess | GetCartFailure 
| RemoveFromCart | RemoveFromCartSuccess | RemoveFromCartFailure;

import { UserModel } from "../../models";
import {
  Users,
  CREATE_USER,
  GET_USERS,
  FIND_USERS,
  UserActionTypes,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
} from "./types";
import { arrayOf } from "prop-types";

const INITIAL_STATE: Users = {
  users: new Array(),
  user: new UserModel(),
  success: false,
  loading: false,
};

export function userReducer(
  state = INITIAL_STATE,
  action: UserActionTypes
): Users {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        user: action.payload,
        success: true,
      };
    case GET_USERS:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        users: action.payload
      };
    case GET_USERS_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case FIND_USERS:
      return {
        ...state,
        users: action.payload,
        success: true,
      };
    default:
      return state;
  }
}

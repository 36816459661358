import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import React from "react";
import { GOOGLE_MAPS_API_KEY } from "../constants";
import { toast } from "react-toastify";

interface IMapContanerProps {
  google: any;
}
interface IMapContainerState {
  showingInfoWindow: boolean;
  activeMarker: any;
  selectedPlace: any;
  mapReadyFlag: boolean;
}

class MapContainer extends React.Component<
  IMapContanerProps,
  IMapContainerState
> {
  constructor(props: IMapContanerProps) {
    super(props);

    this.state = {
      showingInfoWindow: false,
      activeMarker: {},
      selectedPlace: {},
      mapReadyFlag: false,
    };

    this.onMarkerClick = this.onMarkerClick.bind(this);
    this.onMapClicked = this.onMapClicked.bind(this);
    this.onMapReady = this.onMapReady.bind(this);
  }

  onMarkerClick = (props: any, marker: any, e: any) => {
    try {
      this.setState({
        selectedPlace: props,
        activeMarker: marker,
        showingInfoWindow: true,
      });
    } catch (ex) {
      console.log("EX: ", ex.message);
    }
  };

  onMapClicked = (props: any) => {
    const { showingInfoWindow } = this.state;
    if (showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  onMapReady() {
    this.setState({
      mapReadyFlag: true,
    });
  }

  render() {
    const {
      activeMarker,
      showingInfoWindow,
      selectedPlace,
      mapReadyFlag,
    } = this.state;
    const { google } = this.props;
    const mapStyles = {
      width: "100%",
      height: "100%",
    };
    const zoom = 8;
    return (
      <div className="ui segment" style={{ height: "50vh", padding: "0" }}>
        <Map
          google={this.props.google}
          initialCenter={{ lat: -8.1286478, lng: -79.0380899 }}
          style={mapStyles}
          onReady={this.onMapReady}
          onClick={this.onMapClicked}
        >
          <Marker
            // mapCenter={{ lat: -8.1286478, lng: -79.0380899 }}
            onClick={this.onMarkerClick}
            // name={'Camelot'}
          />
          <InfoWindow
            visible={showingInfoWindow}
            marker={activeMarker}
            google={google}
            map={google.Map}
          >
            <div>
              <h3> {selectedPlace.name} </h3>
            </div>
          </InfoWindow>

          {/* <Marker mapCenter={{ lat: -8.130, lng: -79.51}}/>  */}
        </Map>
      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: GOOGLE_MAPS_API_KEY,
})(MapContainer);

// export default MapContainer;

import { RoleModel, StoreModel } from ".";

export class UserModel {
    id: number | undefined;
    name: string | undefined;
    surname: string | undefined;
    cellphone: string | undefined;
    address: string | undefined;
    email!: string;
    googleId: string | undefined;
    facebookId: string | undefined;
    profileImage: string | undefined;
    createdAt: number | undefined;
    updatedAt: number | undefined;
    storeId: number | undefined;
    password: string | undefined;
    role!: RoleModel;
    store: StoreModel | undefined;
}
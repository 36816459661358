import { axiosAuthGET, axiosAuthPOST } from "./Setup";
import { ShoppingCartModel, ProductModel } from "../models";

const baseURL = 'shoppingCart';

export class ShoppingCartService {

    public async getShoppingCart(token: string) {
        let url = baseURL;
        try {
            // const params = {
            //     name: storeName
            // }
            const response = await axiosAuthGET(url, token);
            const parsed = JSON.parse(response.data);
            return parsed;
        } catch (ex) {
            throw new Error(ex.message)
        }
    }

    public async addToCart(productData: ProductModel, token: string) {
        let url = baseURL;
        try {
            const response = await axiosAuthPOST(url, productData, token);
            const parsed = JSON.parse(response.data);
            return parsed;
        } catch (ex) {
            throw new Error(ex.message)
        }
    }
}
import {
  ShoppingCart,
  ADD_TO_CART,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_SUCCESS,
  GET_CART,
  GET_CART_FAILURE,
  GET_CART_SUCCESS,
  REMOVE_FROM_CART,
  REMOVE_FROM_CART_FAILURE,
  REMOVE_FROM_CART_SUCCESS,
  ShoppingCartActionTypes,
} from "./types";
import { ShoppingCartModel, ProductModel } from "../../models";
import { Dispatch } from "react";
import { AppState } from "..";
import { shoppingCartService } from "../../service";

export function addToCart(): ShoppingCartActionTypes {
  return {
    type: ADD_TO_CART,
  };
}

export function addToCartSuccess(
  shoppingCart: ShoppingCartModel[]
): ShoppingCartActionTypes {
  return {
    type: ADD_TO_CART_SUCCESS,
    payload: shoppingCart,
  };
}

export function addToCartFailure(): ShoppingCartActionTypes {
  return {
    type: ADD_TO_CART_FAILURE,
  };
}

export function getCart(): ShoppingCartActionTypes {
  return {
    type: GET_CART,
  };
}

export function getCartSuccess(
  shoppingCart: ShoppingCartModel[]
): ShoppingCartActionTypes {
  return {
    type: GET_CART_SUCCESS,
    payload: shoppingCart,
  };
}

export function getCartFailure(): ShoppingCartActionTypes {
  return {
    type: GET_CART_FAILURE,
  };
}

export function removeFromCart(): ShoppingCartActionTypes {
  return {
    type: REMOVE_FROM_CART,
  };
}

export function removeFromCartSuccess(
  shoppingCart: ShoppingCartModel[]
): ShoppingCartActionTypes {
  return {
    type: REMOVE_FROM_CART_SUCCESS,
    payload: shoppingCart,
  };
}

export function removeFromCartFailure(): ShoppingCartActionTypes {
  return {
    type: REMOVE_FROM_CART_FAILURE,
  };
}

/////
export const startAddToCart = (productData: ProductModel) => {
  return async (
    dispatch: Dispatch<ShoppingCartActionTypes>,
    getState: () => AppState
  ) => {
    try {
      dispatch(addToCart());
      const token = localStorage.getItem("token");
      if (token) {
        const response = await shoppingCartService.addToCart(
          productData,
          token
        );
        return dispatch(addToCartSuccess(response));
      } else {
        return dispatch(addToCartFailure());
      }
    } catch (ex) {
      return dispatch(addToCartFailure());
    }
  };
};

export const startGetShoppingCart = () => {
  return async (
    dispatch: Dispatch<ShoppingCartActionTypes>,
    getState: () => AppState
  ) => {
    try {
      dispatch(getCart());
      const token = localStorage.getItem("token");
      if (token) {
        const response = await shoppingCartService.getShoppingCart(token);
        return dispatch(getCartSuccess(response));
      } else {
        return dispatch(getCartFailure());
      }
    } catch (ex) {
      return dispatch(getCartFailure());
    }
  };
};

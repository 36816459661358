import { axiosGET, axiosPOST, axiosAuthPOST, axiosAuthGET } from "./Setup";
import axios from "axios";
import { StoreModel } from "../models";

const baseURL = "stores";

export class StoreService {
  public async findStores(keyword: string): Promise<StoreModel[]> {
    let url = baseURL + "/find";
    let params = { name: keyword };
    const response = await axiosGET(url, params);
    // const parsed = JSON.parse(response.data);
    const stores = JSON.parse(response.data);
    // parsed.forEach(function (element: StoreModel) {
    //     stores.push(element);
    // });
    // stores.map(function (value, i ) {
    // })
    return stores;
  }

  public async getStores(token: string): Promise<StoreModel[]> {
    let url = baseURL;
    try {
      const response = await axiosAuthGET(url, token);
      const parsed = JSON.parse(response.data);
      return parsed;
    } catch (ex) {
      throw new Error(ex.message);
    }
    // const stores = new Array();
  }

  public async createStores(store: StoreModel, token: string) {
    let url = baseURL;
    const response = await axiosAuthPOST(url, store, token);
    return response;
  }
}

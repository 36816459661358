import { StoreModel } from "../../models";

export const CREATE_STORE = "CREATE_STORE";

export const GET_STORES = "GET_STORES";
export const GET_STORES_SUCCESS = "GET_STORES_SUCCESS";
export const GET_STORES_FAILURE = "GET_STORES_FAILURE";

export const FIND_STORES = "FIND_STORES";

export interface Enterprises {
  enterprises: StoreModel[];
  enterprise: StoreModel;
  success: boolean;
  loading: boolean;
}

interface CreateStoreAction {
  type: typeof CREATE_STORE;
  payload: StoreModel;
}

interface GetStores {
  type: typeof GET_STORES;
}

interface GetStoresSuccess {
  type: typeof GET_STORES_SUCCESS;
  payload: StoreModel[];
}

interface GetStoresFailure {
  type: typeof GET_STORES_FAILURE;
}

interface FindStores {
  type: typeof FIND_STORES;
  payload: StoreModel[];
}

export type StoreActionTypes =
  | CreateStoreAction
  | FindStores
  | GetStores
  | GetStoresSuccess
  | GetStoresFailure;

import React from "react";
import { Link } from "react-router-dom";
import GoogleAuth from "./GoogleAuth";
import { render } from "@testing-library/react";

interface IHeaderProps {}

interface IHeaderState {}

type Props = IHeaderProps;
class Footer extends React.Component<Props, IHeaderState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {}

  componentWillUnmount() {}

  renderFooter() {
    return (
      <div id="footer"
        className="ui vertical footer segment color-white-important footer-padding "
        style={{ marginBottom: "-20px" , background: 'rgb(11,54,59)'}}
      >
        <div className="ui container">
          <div className="ui stackable  inverted divided equal height centered stackable grid">
            <div className="row">         
            <h4 className="ui white footer-header"> CONTACTO </h4> </div>
            <div className="five wide column no-grid-shadow">
              {/* <h4 className="ui header white" style={{fontSize: '1.371429rem'}}> CONTACTO </h4> */}
              <div className="ui link list footer-list">
                <li className="item white" > Calle la Arboleda Mz.5 Lt.9 </li>
                <li className="item white"> Urb. Jardines del Golf </li>
                <li className="item white"> Victor Larco Herrera </li>
                <li className="item white"> Trujillo - Perú </li>
              </div>
            </div>
            <div className="five wide column grid-shadow middle-column">
              {/* <h4 className="ui header white"> </h4> */}
              {/* <br /> */}
              <div className="ui link list footer-list">
                <li className="item white"> <a href="tel:(+51)044615251" className="white"> Fijo: +51 044 615 251 </a></li>
                <li className="item white"> <a href="tel:(+51)985815367" className="white"> Cel: +51 985 815 367 </a></li>
                <li className="item white"> <a href="tel:(+51)964605509" className="white"> Cel: +51 964 605 509 </a></li>
                <li className="item white"> <a href="https://api.whatsapp.com/send/?phone=51985815367"  target="_blank" className="white"> Whatsapp Business: +51 985 815 367 </a></li>
              </div>
            </div>
            <div className="five wide column grid-shadow right-column ">
              {/* <h4 className="ui header white"> </h4> */}
              {/* <br /> */}
              <div className="ui link list footer-list">
                <li className="item white">Email: info@epyco.pe</li>
                <li className="item white">Email: proyectos@epyco.pe</li>
                <li className="item white">Email: inmobiliaria@epyco.pe </li>
                {/* <li className="item">Drive: abocanegra@cip.org.pe </li> */}
              </div>
            </div>
          </div> <br /><br />
          <p className="item white" style={{textAlign: 'center'}}> <span> Copyright <i className="copyright outline icon" /> 2023 - 2024 Epyco Inmobiliaria y Construcción. Todos los derechos reservados.</span></p>
        </div>
      </div>
    );
  }
  render() {
    return this.renderFooter();
  }
}

export default Footer;

import { axiosGET, axiosPOST, axiosAuthPOST, axiosAuthGET } from './Setup';
import { UserModel } from '../models';
import { Auth } from '../store/auth/types';

const baseURL = 'users';

export class UserService {

    public async findUsers(keyword: string): Promise<UserModel[]> {
        let url = baseURL + '/find';
        let params = { name: keyword }
        const response = await axiosGET(url, params);
        const users: UserModel[] = JSON.parse(response.data);
        return users;
    }

    public async getUsers(token: string): Promise<UserModel[]> {
        let url = baseURL;
        // const stores = new Array();
        const response = await axiosAuthGET(url, token);
        const users: UserModel[] = JSON.parse(response.data);
        return users;
    }

    public async createUser(user: UserModel) {
        let url = baseURL;
        const response = await axiosPOST(url, user);

        return response;
    }

    public async signIn(userData: UserModel) : Promise<Auth>{
        let url = baseURL + '/signIn';
        try {
            const response = await axiosPOST(url, userData);
            const parsed = JSON.parse(response.data);
            const data : Auth = {
                user: parsed.user,
                isSignedIn: true,
                token: parsed.token
            }
            return data;
        } catch (ex) {
            throw new Error(ex.message);
        }

    }

    public async redeemToken(token: string) : Promise<Auth>{
        let url = baseURL + '/redeem';
        try {
            const response = await axiosAuthGET(url, token);
            const parsed = JSON.parse(response.data);
            const data : Auth = {
                user: parsed.user,
                isSignedIn: true,
                token: parsed.token
            }
            return data;
        } catch (ex) {
            throw new Error(ex.message);
        }
    }
}
import {
  UserActionTypes,
  CREATE_USER,
  GET_USERS,
  FIND_USERS,
  GET_USERS_SUCCESS,
  GET_USERS_FAILURE,
} from "./types";
import { UserModel } from "../../models";
import { AppState } from "..";
import { Dispatch } from "redux";
import { userService } from "../../service";

export function createUser(user: UserModel): UserActionTypes {
  return {
    type: CREATE_USER,
    payload: user,
  };
}

export function getUsers(): UserActionTypes {
  return {
    type: GET_USERS,
  };
}

export function getUsersSuccess(users: UserModel[]): UserActionTypes {
  return {
    type: GET_USERS_SUCCESS,
    payload: users,
  };
}

export function getUsersFailure(): UserActionTypes {
  return {
    type: GET_USERS_FAILURE,
  };
}

export function findUsers(users: UserModel[]): UserActionTypes {
  return {
    type: FIND_USERS,
    payload: users,
  };
}

export const startCreateUser = (userData: UserModel) => {
  return async (
    dispatch: Dispatch<UserActionTypes>,
    getState: () => AppState
  ) => {
    await userService.createUser(userData);

    return dispatch(createUser({ ...userData }));
  };
};

export const startGetUsers = () => {
  return async (
    dispatch: Dispatch<UserActionTypes>,
    getState: () => AppState
  ) => {
    try {
        console.log('Start')
      const token = localStorage.getItem("token");
      if (token) {
        const users: UserModel[] = await userService.getUsers(token);
        return dispatch(getUsersSuccess(users));
      } else {
        return dispatch(getUsersFailure());
      }
    } catch (ex) {
        console.log('Error: ', ex.message)
      return dispatch(getUsersFailure());
    }
  };
};

export const startFindUsers = (search: string) => {
  return async (
    dispatch: Dispatch<UserActionTypes>,
    getState: () => AppState
  ) => {
    const stores: UserModel[] = await userService.findUsers(search);
    return dispatch(findUsers({ ...stores }));
  };
};

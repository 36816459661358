import React from "react";
import Home from "./Home";
import EpycoHome from "./EpycoHome"
import { EpycoNosotros } from "./Epyco/";
import { BrowserRouter, Route, Router } from "react-router-dom";
import { UserCreate, UserDelete, UserEdit, UserList, Account } from "./Users";
import { StoreCreate, StoreList, StorePage } from "./StorePages";
import { ProductCreate, ProductList, ProductDetail } from "./Products";
import { CategoryCreate, CategoryList } from './Categories';
import GoogleAuth from "../components/GoogleAuth";
import Footer from "../components/Footer";
import history from "../history";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Checkout from "./ShoppingCart/Checkout";
import Admin from "./Admin/Admin";
// import MainLayout from '../layouts/main-layout';

export class Pages extends React.Component {
  render() {
    return (
      // <div>
        <Router history={history}>
          <div className=""> {/* pusher */}
            <Route path="/" exact component={EpycoHome} />
            <Route path="/NOSOTROS23" exact component={EpycoNosotros} />
            {/* <Route path="/users" exact component={UserList} />
            <Route path="/users/new" exact component={GoogleAuth} />
            <Route path="/users/create" exact component={UserCreate} />
            <Route path="/users/edit" exact component={UserEdit} />
            <Route path="/users/delete" exact component={UserDelete} />
            <Route path="/account" exact component={Account} />
            <Route path="/stores" exact component={StoreList} />
            <Route path="/tiendas/:store/" exact component={StorePage} />
            <Route path="/tiendas/:store/productos" exact component={ProductList} />
            <Route path="/tiendas/:store/productos/nuevo" exact component={ProductCreate} />
            <Route path="/tiendas/:store/productos/editar" exact component={ProductCreate} />
            <Route path="/tiendas/:store/productos/detalle/:id" exact component={ProductDetail} />
            <Route path="/admin/stores/nuevo" exact component={StoreCreate} />
            <Route path="/products/create" exact component={ProductCreate} />
            <Route path="/products" exact component={ProductList} />
            <Route path="/productos/:id" exact component={ProductDetail} />
            <Route path="/categories/create" exact component={CategoryCreate} />
            <Route path="/categories" exact component={CategoryList} />
            <Route path="/tiendas/:store/checkout" exact component={Checkout} />
            <Route path="/admin" exact component={Admin} /> */}
          </div>
        {/* <Footer /> */}
        {/* <ToastContainer />   */}
        </Router>
      // </div>
    );
  }
}

import { StoreModel } from "../../models";
import {
  Enterprises,
  CREATE_STORE,
  GET_STORES,
  FIND_STORES,
  StoreActionTypes,
  GET_STORES_FAILURE,
  GET_STORES_SUCCESS,
} from "./types";
import { arrayOf } from "prop-types";

const INITIAL_STATE: Enterprises = {
  enterprises: new Array(),
  enterprise: new StoreModel(),
  success: false,
  loading: false,
};

export function storesReducer(
  state = INITIAL_STATE,
  action: StoreActionTypes
): Enterprises {
  switch (action.type) {
    case CREATE_STORE:
      return {
        ...state,
        enterprise: action.payload,
        success: true,
      };
    case GET_STORES:
      return {
        ...state,
        loading: true,
      };
    case GET_STORES_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
      };
    case GET_STORES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        enterprises: action.payload,
      };
    case FIND_STORES:
      return {
        ...state,
        enterprises: action.payload,
        success: true,
      };
    default:
      return state;
  }
}

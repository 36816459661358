import { CategoryModel } from '../../models';

export const CREATE_CATEGORY = 'CREATE_CATEGORY';
export const GET_CATEGORIES = 'GET_CATEGORIES';
export const FIND_CATEGORIES = 'FIND_CATEGORIES';

export interface Categories {
    categories: CategoryModel[];
    category: CategoryModel;
    success: boolean;
}

interface CreateCategoryAction {
    type: typeof CREATE_CATEGORY,
    payload: CategoryModel,
}

interface GetCategories {
    type: typeof GET_CATEGORIES,
    payload: CategoryModel[],
}

interface FindCategories {
    type: typeof FIND_CATEGORIES,
    payload: CategoryModel[],
}

export type CategoryActionTypes = CreateCategoryAction | GetCategories | FindCategories
import React from "react";

import Carousel from "react-multi-carousel";
import { Image } from "semantic-ui-react";

import "../css/Epyco.css";
import F1 from "../images/F1.webp";
import F2 from "../images/F2.webp";
import F3 from "../images/F3.webp";
import F4 from "../images/F4.webp";
import F5 from "../images/F5.webp";

import BasicLogo from "../images/BasicLogo2.png";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

function renderImage(image: any, key: string) {
  return (
    <div>
      <Image src={image} className="epyco-carousel-image-fading" alt="F1" />
    </div>
  );
}
function renderDiv(image: any, key: string) {
  return <div className={"banner-carousel-" + key} />;
}

export default function EpycoCarousel() {
  return (
    <div>
      <Carousel
        ssr
        partialVisbile
        // deviceType={deviceType}
        itemClass="image-item"
        draggable={false}
        responsive={responsive}
        infinite={true}
        arrows={false}
        autoPlay={true}
        autoPlaySpeed={5000}
        transitionDuration={300}
        partialVisible={true}
        showDots={false}
      >
        {/* <div>
        <Image src={F1} className="epyco-carousel-image" alt="F1" />
        <div className="banner-caption">
          <img src={BasicLogo} className="home-basic-logo" alt="Logo" />
        </div>
      </div> */}
        {renderDiv(F1, "1")}
        {renderDiv(F2, "2")}
        {renderDiv(F3, "3")}
        {renderDiv(F4, "4")}
        {renderDiv(F5, "5")}
      </Carousel>
      <div className="banner-caption">
        <img src={BasicLogo} className="home-basic-logo" alt="Logo"/>
      </div>
    </div>
  );
}

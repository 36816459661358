export class StoreModel {
    id: string | undefined;
    name: string | undefined;
    address: string | undefined;
    email: string | undefined;
    logo: string | undefined;
    description: string | undefined;
    cellphone: string | undefined;
    createdAt: number | undefined;
    updatedAt: number | undefined;
}
import React from "react";
import "./App.css";
import { Pages } from "./pages";
class App extends React.Component {
  render() {
    return (
        <Pages />
    );
  }
}

export default App;

import React from "react";
import { Link } from "react-router-dom";
import GoogleAuth from "./GoogleAuth";
import { render } from "@testing-library/react";
import LoginModal from "./LoginModal";
import { Auth } from "../store/auth/types";
import { AppState } from "../store";
import { bindActionCreators } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../store/types";

import { startSignOut, startRedeemToken } from "../store/auth/actions";
import { connect } from "react-redux";

interface IHeaderProps {}

interface IHeaderState {
  transform: number;
  scrollTop: number;
  isModalOpen: boolean;
  isRegister: boolean;
  uncover: boolean;
  showCart: boolean;
}

type Props = IHeaderProps & LinkStateProps & LinkDispatchProps;

class HomeHeader extends React.Component<Props, IHeaderState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      transform: 0,
      scrollTop: 0,
      isModalOpen: false,
      isRegister: false,
      uncover: false,
      showCart: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.openSignInModal = this.openSignInModal.bind(this);
    this.openRegisterModal = this.openRegisterModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.renderModal = this.renderModal.bind(this);
    this.toggleUncover = this.toggleUncover.bind(this);
    this.checkLocalToken = this.checkLocalToken.bind(this);
    this.renderRightMenu = this.renderRightMenu.bind(this);
    this.toggleCart = this.toggleCart.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", this.handleScroll);
    await this.checkLocalToken();
  }

  async checkLocalToken() {
    const { auth, startRedeemToken } = this.props;
    const token = localStorage.getItem("token");
    if (token) {
      if (!auth.token) {
        await startRedeemToken(token);
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll(event: any) {
    let scrollTop = window.scrollY,
      itemTranslate = Math.min(0, scrollTop / 3 - 60);

    this.setState({
      transform: itemTranslate,
      scrollTop,
    });
  }

  // **** MODAL SECTION STARTS **** //
  closeModal() {
    const { isModalOpen } = this.state;
    if (isModalOpen) {
      this.setState({
        isModalOpen: false,
      });
    }
  }

  openSignInModal() {
    const { isModalOpen } = this.state;
    if (!isModalOpen) {
      this.setState({
        isModalOpen: true,
        isRegister: false,
      });
    }
  }

  openRegisterModal() {
    const { isModalOpen } = this.state;
    if (!isModalOpen) {
      this.setState({
        isModalOpen: true,
        isRegister: true,
      });
    }
  }

  toggleUncover() {
    const { uncover } = this.state;
    this.setState({
      uncover: !uncover,
    });
  }

  toggleCart() {
    const { showCart } = this.state;
    this.setState({
      showCart: !showCart,
    });
  }

  renderModal() {
    const { isModalOpen, isRegister } = this.state;
    return (
      <LoginModal
        isModalOpen={isModalOpen}
        closeModal={this.closeModal}
        isRegister={isRegister}
      />
    );
  }

  renderLinks() {
    return (
      <div>
        <Link to="/" className="active item">
          Enlineate
        </Link>
        {/* <Link to="/stores" className="item">
          Tiendas
        </Link> */}
        {/* <Link to="/products/" className="item">
          Productos
        </Link> */}
        {/* <Link to="/categories" className="item">
          Categorías
        </Link> */}
      </div>
    );
  }

  renderLeftMenu() {
    const { uncover } = this.state;
    const name = uncover
      ? "ui vertical inverted sidebar menu left uncover visible"
      : "ui vertical inverted sidebar menu";
    return (
      <div className={name}>
        <a className="active item"> Enlineate </a>
        <Link to="/products/" className="item">
          {" "}
          Productos{" "}
        </Link>
        {/* <a className="item"> Iniciar Sesión </a>
      <a className="item"> Registrarse </a>
      <a className="item"> Cerrar Sessión </a> */}
        <a className="big item" onClick={this.toggleUncover}>
          {" "}
          <i className="arrow alternate circle left icon large" /> Regresar{" "}
        </a>
      </div>
    );
  }
  renderRightMenu() {
    const { showCart } = this.state;
    const name = showCart
      ? "ui vertical sidebar menu right uncover visible"
      : "ui vertical sidebar menu";
    return (
      <div className={name}>
        <div
          onClick={this.toggleCart}
          className="text-center side-shopping-cart-title"
        >
          <h2>
            <i className="shopping cart icon" />
            Carrito
          </h2>
        </div>
      </div>
    );
  }

  // **** MODAL SECTION ENDS **** //

  renderHomeHeader() {
    const { auth, startSignOut } = this.props;
    return (
      <div>
        <div className="ui mini inverted vertical masthead center aligned segment">
          <div className="ui container">
            <div className="ui large secondary inverted pointing menu">
              <a className="toc item" onClick={this.toggleUncover}>
                <i className="sidebar icon" />
              </a>
              {/* LINKS  */}
              <Link to="/" className="active item">
                Enlineate
              </Link>
              {/* <Link to="/stores" className="item">
                Tiendas
              </Link> */}
              {/* <Link to="/products/" className="item">
                Productos
              </Link> */}
              {/* <Link to="/categories" className="item">
                Categorías
              </Link> */}
              {/* LINKS  */}
              {auth.isSignedIn ? (
                <div className="right item">
                  <a className="ui blue image big label hidden-mobile ">
                    {auth.user.email}
                    <div className="detail">{auth.user.role?.description}</div>
                  </a>
                  <p className="ui inverted button" onClick={startSignOut}>
                    Cerrar Sesión
                  </p>
                  <div className="right item">
                    <i
                      className="shopping cart icon"
                      onClick={this.toggleCart}
                    />
                  </div>
                </div>
              ) : (
                <div className="right item">
                  <a
                    className="ui inverted  button"
                    onClick={this.openSignInModal}
                  >
                    Iniciar Sesión
                  </a>
                  <a
                    className="ui inverted  button"
                    onClick={this.openRegisterModal}
                  >
                    Registrarse
                  </a>
                </div>
              )}
            </div>
          </div>
          <div className="ui text container">
            <h1 className="ui inverted header">Enlineate</h1>
            <h2> Desarrollo y despliegue de Webs y Comercio en línea.</h2>
            {/* <Link to="/products" className="ui huge primary button">
              Productos
              <i className="right arrow icon" />
            </Link> */}
          </div>
        </div>
      </div>
    );
  }
  renderBasic() {
    return (
      <div>
        <div
          className="ui secondary pointing fixed menu"
          style={{ padding: "5px" }}
        >
          <Link to="/" className="item">
            Enlineate (Imagen Logo)
          </Link>
          <div className="right menu">
            <Link to="/" className="item">
              Landing Page
            </Link>
            <GoogleAuth />
            <div className="ui primary button">
              <i className="shop icon" style={{ margin: "0px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderHiddenMenu(scrollTop: number) {
    const { auth, startSignOut } = this.props;
    if (scrollTop > 850) {
      return (
        // hidden
        <div className="ui large top fixed menu">
          <div className="ui container">
            <Link to="/" className="active item">
              Enlineate
            </Link>
            {/* <Link to="/users" className="item">
              Usuarios
            </Link> */}
            {/* <Link to="/stores" className="item">
              Tiendas
            </Link> */}
            {/* <Link to="/products/" className="item">
              Productos
            </Link> */}
            {/* <Link to="/categories" className="item">
              Categorías
            </Link> */}
            {auth.isSignedIn ? (
              <div className="right menu">
                <div className="item">
                  <a className="ui blue image medium label">
                    {auth.user.email}
                    <div className="detail">{auth.user.role?.description}</div>
                  </a>
                </div>
                <div className="item" onClick={startSignOut}>
                  <a className="ui button"> Cerrar Sesión </a>
                </div>
                <div className="right item">
                  <i className="shopping cart icon" onClick={this.toggleCart} />
                </div>
              </div>
            ) : (
              <div className="right menu">
                <div className="item" onClick={this.openSignInModal}>
                  <a className="ui button"> Iniciar Sesión </a>
                </div>
                <div className="item" onClick={this.openRegisterModal}>
                  <a className="ui button"> Registrarse </a>
                </div>
              </div>
            )}
          </div>
        </div>
      );
    }
  }
  render() {
    const { transform, scrollTop } = this.state;
    return (
      // fixed hidden
      <div>
        {/* {this.renderBasic()} */}
        {this.renderModal()}
        {this.renderLeftMenu()}
        {this.renderRightMenu()}
        {this.renderHiddenMenu(scrollTop)}

        {this.renderHomeHeader()}
      </div>
    );
  }
}

interface LinkStateProps {
  auth: Auth;
}

interface LinkDispatchProps {
  startSignOut: () => void;
  startRedeemToken: (token: string) => void;
}

const mapStateToProps = (state: AppState, ownProps: IHeaderProps) => ({
  auth: state.auth,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IHeaderProps
): LinkDispatchProps => ({
  startSignOut: bindActionCreators(startSignOut, dispatch),
  startRedeemToken: bindActionCreators(startRedeemToken, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(HomeHeader);

import React, { useCallback } from "react";
import { AppActions } from "../store/types";
import { AppState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { connect } from "react-redux";

interface IState {}

interface IProps {}

type Props = IProps & LinkStateProps & LinkDispatchProps;

class UploadImage extends React.Component<Props, IState> {
  constructor(props: Props) {
    super(props);

    this.state = {};
  }

  renderDropzone() {

  }

  render() {
    return (
      <div>
        <p> Vamos a subir imágenes </p>
        <p> Dropzone here </p>
        {this.renderDropzone()}
      </div>
    );
  }
}

interface LinkStateProps {}

interface LinkDispatchProps {}

const mapStateToProps = (state: AppState, ownProps: IProps) => ({
  enterprises: state.stores,
  user: state.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IProps
): LinkDispatchProps => ({});

export default connect(mapStateToProps, mapDispatchToProps)(UploadImage);

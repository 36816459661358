import { ShoppingCartModel } from "../../models";
import {
  ShoppingCart,
  ADD_TO_CART,
  ADD_TO_CART_FAILURE,
  ADD_TO_CART_SUCCESS,
  GET_CART,
  GET_CART_FAILURE,
  GET_CART_SUCCESS,
  REMOVE_FROM_CART,
  REMOVE_FROM_CART_FAILURE,
  REMOVE_FROM_CART_SUCCESS,
  ShoppingCartActionTypes,
} from "./types";

const INITIAL_STATE: ShoppingCart = {
  shoppingCart: new Array(),
  success: false,
  loading: false,
};

export function shoppingCartReducer(
  state = INITIAL_STATE,
  action: ShoppingCartActionTypes
): ShoppingCart {
  switch (action.type) {
    case ADD_TO_CART:
      return {
        ...state,
        loading: true,
      };
    case ADD_TO_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        shoppingCart: action.payload,
      };
    case ADD_TO_CART_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        shoppingCart: INITIAL_STATE.shoppingCart,
      };
    case GET_CART:
      return {
        ...state,
        loading: true,
      };
    case GET_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        shoppingCart: action.payload,
      };
    case GET_CART_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        shoppingCart: new Array(),
      };
    case REMOVE_FROM_CART:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_FROM_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        shoppingCart: action.payload,
      };
    case REMOVE_FROM_CART_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        shoppingCart: new Array(),
      };
    default:
      return state;
  }
}

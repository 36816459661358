import { UserModel } from '../../models';

export const CREATE_USER = 'CREATE_USER';
export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';
export const FIND_USERS = 'FIND_USERS';

export interface Users {
    users: UserModel[];
    user: UserModel;
    success: boolean;
    loading: boolean;
}

interface CreateUserAction {
    type: typeof CREATE_USER,
    payload: UserModel,
}

interface GetUsers {
    type: typeof GET_USERS,
}

interface GetUsersSuccess {
    type: typeof GET_USERS_SUCCESS,
    payload: UserModel[],
}

interface GetUsersFailure {
    type: typeof GET_USERS_FAILURE,
}

interface FindUsers {
    type: typeof FIND_USERS,
    payload: UserModel[],
}

export type UserActionTypes = CreateUserAction | GetUsers | FindUsers |
GetUsersFailure | GetUsersSuccess;
import { StoreModel, CategoryModel } from ".";

export class ProductModel {
    id: string | undefined;
    name: string | undefined;
    description: string | undefined;
    image: any | undefined;
    imageName: string | undefined;
    imageType: string | undefined;
    price!: number;
    discount: number | undefined;
    createdAt: number | undefined;
    updatedAt: number | undefined;
    store: any | undefined;
    storeId: string | undefined;
    categoryId: string | undefined;
    category!: CategoryModel;
    quantity: number | undefined;
}
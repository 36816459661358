import React from "react";
import { bindActionCreators } from "redux";
import "../css/Header.css";
import "../css/Modal.css";
import Logo from "../images/Logo.png";
import { toast, ToastContainer } from 'react-toastify';

import { connect } from "react-redux";
import { startSignIn, startSignOut } from "../store/auth/actions";
import { startCreateUser } from "../store/user/actions";
import { AppState } from "../store";
import { UserModel } from "../models";
import { Auth } from "../store/auth/types";
import { AppActions } from "../store/types";
import { ThunkDispatch } from "redux-thunk";
import { Modal } from "react-bootstrap";
import { Users } from "../store/user/types";

interface ILoginModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  isRegister: boolean;
}

interface ILoginModalState {
  email: string;
  password: string;
  cpassword: string;
  loading: boolean;
}

type Props = ILoginModalProps & LinkStateProps & LinkDispatchProps;

class LoginModal extends React.Component<Props, ILoginModalState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      cpassword: "",
      loading: false,
    };

    this.onSignInClick = this.onSignInClick.bind(this);
    this.onSignOutClick = this.onSignOutClick.bind(this);
    this.renderAuthButton = this.renderAuthButton.bind(this);
    this.onAuthChange = this.onAuthChange.bind(this);
    this.menuDropdown = this.menuDropdown.bind(this);
    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
    this.handleSignInSubmit = this.handleSignInSubmit.bind(this);
    this.renderRegisterForm = this.renderRegisterForm.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  async handleRegisterSubmit(event: any) {
    event.preventDefault();
    const { email, password, cpassword } = this.state;
    const { closeModal } = this.props;
    if (email && password && cpassword) {
      const newUser = new UserModel();
      newUser.email = email;
      newUser.password = password;
      try {
        this.setState({ loading: true });
        await this.props.startCreateUser(newUser);
        this.setState({
          loading: false,
          email: "",
          password: "",
          cpassword: "",
        });
        toast.info("Usuario registrado correctamente.")
        closeModal();
      } catch (ex) {
        this.setState({ loading: false })
      }
    } else {
      toast.error("Faltan datos.");
    }
  }

  async handleSignInSubmit(event: any) {
    event.preventDefault();
    const { email, password } = this.state;
    const { closeModal } = this.props;

    if (email && password) {
      const newUser = new UserModel();

      newUser.email = email;
      newUser.password = password;

      try {
        this.setState({ loading: true })
        await this.props.startSignIn(newUser);
        this.setState({
          loading: false,
          email: "",
          password: "",
          cpassword: "",
        });
        closeModal();
      } catch (ex) {
        this.setState({ loading: false })
        toast.error("No se pudo iniciar sesión.")
        // alert(ex.message);
      }
    }
  }

  handleChange(event: any) {
    const target = event.target;
    const name = target.name;
    const value = name === "selectedFile" ? target.files[0] : target.value; // May Need and Update for checks
    this.setState({
      [name]: value,
    } as ILoginModalState);
  }

  renderOAuthButtons() {
    return (
      <div className="modal-buttons">
        <button className="ui red google button" 
        // onClick={this.onSignInClick}
        >
          <i className="google icon" />
          Iniciar Sesión
        </button>
        <button
          className="ui blue facebook button"
          // onClick={this.onSignInClick}
        >
          <i className="facebook icon" />
          Iniciar Sesión
        </button>
      </div>
    );
  }
  renderSignInForm() {
    const { email, password } = this.state;
    return (
      <form className="ui form">
        <div className="modal-field">
          <label> Correo </label>
          <input
            type="text"
            name="email"
            value={email}
            placeholder="Correo"
            onChange={this.handleChange}
          />
        </div>
        <div className="field">
          <label> Contraseña </label>
          <input
            type="password"
            name="password"
            value={password}
            placeholder="Password"
            onChange={this.handleChange}
          />
        </div>
        <div className="modal-buttons">
          <button
            className="ui secondary button"
            type="button"
            onClick={this.handleSignInSubmit}
          >
            Iniciar Sesión
          </button>
          <button
            className="ui secondary button"
            onClick={this.props.closeModal}
          >
            Cancelar
          </button>
          {this.renderOAuthButtons()}
        </div>
      </form>
    );
  }

  renderRegisterForm() {
    const { email, password, cpassword } = this.state;
    return (
      <form className="ui form">
        <div className="modal-field">
          <label> Correo </label>
          <input
            type="text"
            name="email"
            value={email}
            placeholder="Correo"
            onChange={this.handleChange}
          />
        </div>
        <div className="field">
          <label> Contraseña </label>
          <input
            type="password"
            name="password"
            value={password}
            placeholder="Password"
            onChange={this.handleChange}
          />
        </div>
        <div className="field">
          <label> Confirmar Contraseña </label>
          <input
            type="password"
            name="cpassword"
            value={cpassword}
            placeholder="Password"
            onChange={this.handleChange}
          />
        </div>
        <div className="modal-buttons">
          <button
            className="ui secondary button"
            type="button"
            onClick={this.handleRegisterSubmit}
          >
            Guardar
          </button>
          <button
            className="ui secondary button"
            onClick={this.props.closeModal}
          >
            Cancelar
          </button>
          {this.renderOAuthButtons()}
        </div>
      </form>
    );
  }

  renderModal() {
    const { loading } = this.state;
    const { isModalOpen, closeModal, isRegister } = this.props;
    if (isModalOpen) {
      return (
        <div className="modal">
          <div className="modal-content">
            {loading && (
              <div className="ui active dimmer round-border">
                <div className="ui huge text loader round-border">
                  {" "}
                  Procesando{" "}
                </div>
              </div>
            )}
            <button
              className="ui right floated secondary button modal-close"
              onClick={closeModal}
            >
              X
            </button>
            <div className="modal-header">
              <img className="modal-logo" src={Logo} />
              <p> {isRegister ? "Registro" : "Iniciar Sesión"} </p>
            </div>
            {isRegister ? this.renderRegisterForm() : this.renderSignInForm()}
          </div>
        </div>
      );
    }
  }

  menuDropdown() {
    const { auth } = this.props;
    const {} = this.state;
    // if(isCardModalOpen) {
    return (
      <div>
        <Modal.Dialog>
          <Modal.Header closeButton>
            <Modal.Title>
              <img src={auth?.user?.profileImage} alt="profile" />{" "}
              {auth?.user?.name}
            </Modal.Title>
          </Modal.Header>
        </Modal.Dialog>
        <Modal.Body>
          {/* <Card style={{ width: "18rem", textAlign: "center" }}>
              <Card.Img
                variant="top"
                src={auth?.user?.profileImage}
                style={{ borderRadius: "50%" }}
              />
              <Card.Body>
                <Card.Title> {auth?.user?.name} </Card.Title>
                <Card.Text> Lorem Ipsum Ip Dolorem</Card.Text>
                <button
                  className="ui google plus button"
                  onClick={this.onSignOutClick}
                >
                  <i className="google icon" />
                  Cerrar Sesión
                </button>
              </Card.Body>
            </Card> */}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </div>
      // } else {

      // }

      //     </Dropdown.Item>
      //   </Dropdown.Menu>
      // </Dropdown>
    );
  }

  renderAuthButton() {
    const { isSignedIn, auth } = this.props;
    if (isSignedIn === null) {
      return <div> {null} </div>;
    } else if (isSignedIn) {
      if (auth?.user?.profileImage === null) {
        return (
          <div>
            <button
              className="ui red google button"
              onClick={this.onSignOutClick}
            >
              <i className="google icon" />
              Cerrar Sesión
            </button>
          </div>
        );
      } else {
        return (
          <div className="divImgAvatar">
            <img
              src={auth?.user?.profileImage}
              className="ui avatar image"
              alt="profile"
            />
          </div>
        );
      }
    } else {
      return (
        <div>
          <button className="ui red google button" onClick={this.onSignInClick}>
            <i className="google icon" />
            Iniciar Sesión
          </button>
        </div>
      );
    }
  }

  async onAuthChange(isSignedIn: boolean) {
    if (isSignedIn) {
      const auth = window.gapi.auth2.getAuthInstance();
      const basicProfile = await auth.currentUser.get().getBasicProfile();

      let userData = new UserModel();
      userData.email = basicProfile.getEmail();
      userData.profileImage = basicProfile.getImageUrl();
      userData.name = basicProfile.getGivenName();
      userData.surname = basicProfile.getFamilyName();


      this.props.startSignIn(userData);
    } else {
      this.props.startSignOut();
    }
  }

  async onSignInClick() {
    const auth = window.gapi.auth2.getAuthInstance();
    await auth.signIn();
  }

  async onSignOutClick() {
    const auth = window.gapi.auth2.getAuthInstance();
    await auth.signOut();
  }
  componentDidMount() {
    // window.gapi.load("client:auth2", () => {
    //   window.gapi.client
    //     .init({
    //       clientId: CONSTANTS.gclientid,
    //       scope: "email",
    //     })
    //     .then(() => {
    //       const auth = window.gapi.auth2.getAuthInstance();
    //       const isSignedIn = auth.isSignedIn.get();
    //       this.setState({
    //         auth: auth,
    //         isSignedIn: isSignedIn,
    //       });
    //       this.onAuthChange(isSignedIn);
    //       auth.isSignedIn.listen(this.onAuthChange);
    //     });
    // });
  }
  render() {
    const { auth } = this.props;
    return ( <div> 
      {this.renderModal()} 
    {/* <ToastContainer />  */}
    </div>);
  }
}

interface LinkStateProps {
  auth?: Auth;
  isSignedIn: boolean;
  user: Users;
}

interface LinkDispatchProps {
  startSignIn: (userData: UserModel) => void;
  startSignOut: () => void;
  startCreateUser: (userData: UserModel) => void;
}

const mapStateToProps = (state: AppState, ownProps: ILoginModalProps) => ({
  isSignedIn: state.auth.isSignedIn,
  auth: state.auth,
  user: state.user,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: ILoginModalProps
): LinkDispatchProps => ({
  startSignIn: bindActionCreators(startSignIn, dispatch),
  startSignOut: bindActionCreators(startSignOut, dispatch),
  startCreateUser: bindActionCreators(startCreateUser, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginModal);

import React, { useState, useEffect } from "react";
import { useTransition, animated, config } from "react-spring";
import "../css/spring.css";
import F1 from "../images/F1.webp";
import F2 from "../images/F2.webp";
import F3 from "../images/F3.webp";
import F4 from "../images/F4.webp";
import F5 from "../images/F5.webp";

function Keyframes(items: any) {
  const slides = [
    // { id: 0, url: "https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80"},
    // { id: 1, url: "https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80"},
    // { id: 2, url: "https://images.unsplash.com/photo-1540206395-68808572332f?ixlib=rb-1.2.1&w=1181&q=80"},
    { id: 0, url: F1 },
    { id: 1, url: F2 },
    { id: 2, url: F3 },
    { id: 3, url: F4 },
    // { id: 4, url: F5 },

  ];
  const [index, set] = useState(0);
  const transitions = useTransition(slides[index], item => item.id, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    // from: {opacity: 1, transform: 'translate3d(100%,0,0)' },
    // enter: {opacity: 1, transform: 'translate3d(0%,0,0)' },
    // leave:  {opacity: 1, transform: 'translate3d(-50%,0,0)' },
    config: config.molasses,
  });
  useEffect(
    () => void setInterval(() => set((state) => (state + 1) % 4), 8000),
    []
  );
  return (
    <div className="" 
    style={{ height: "100vh" }}
    >
      {transitions.map(({ item, props, key }) => {
        return (
        //   <animated.img
        //     key={key}
        //     className="bg"
        //     src={item.url}
        //     style={{
        //       ...props,
        //     }}
        //   />
        <animated.div
        key={key}
        className="bg"
        style={{
          ...props,
          backgroundImage: `url(${item.url})`
        }}
      />
        );
      })}
    </div>
  );
}

const bg = {
  position: "absolute",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundSize: "cover",
  backgroundPosition: "center",
  willChange: "opacity",
};

export default Keyframes;

import React, { useState, useEffect } from "react";
import { useTransition, animated, config } from "react-spring";
import logo from "../../logo.svg";
import { Link } from "react-router-dom";

import "../../css/General.css";
import "../../css/Epyco.css";
import "../../css/Header.css";
import "../../css/Banner.css";
import "../../css/Product.css";
import "../../css/font.css";
// import { GoogleMap } from "../components";
// import "bootstrap/dist/css/bootstrap.min.css";


import {SpringDemo, BottomOverlay, Keyframes, EpycoCarousel, StaticHeader}  from "../../components/";

import BasicLogo from "../../images/BasicLogo2.png";

import HouseDesign from "../../images/HouseDesign.png";
import MiniRaboot from "../../images/MiniRaboot.png";
import Something03 from "../../images/Something03.png";
import Camelot from "../../images/Camelot.jpg";
import Grocery from "../../images/Grocery.jpg";
import Music from "../../images/Music.jpg";
import Breakfast from "../../images/breakfast.jpg";


// import T1 from "../../images/T1.png";
// import T2 from "../../images/T2.png";
// import T3 from "../../images/T3.png";
// import T4 from "../../images/T4.png";
// import T5 from "../../images/T5.jpg";
import C1 from "../../images/C1.webp";
import C2 from "../../images/C2.webp";
import C3 from "../../images/C3.webp";
import EW1 from "../../images/EW1.png";
import EW2 from "../../images/EW2.png";
import BR1 from "../../images/BR1.webp";
import BR2 from "../../images/BR2.webp";
import BR3 from "../../images/BR3.webp";

// import { Carousel } from "react-bootstrap";
import Footer from "../../components/Footer";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import MessengerCustomerChat from "react-messenger-customer-chat";

// import {
//   GoogleMap,
//   withGoogleMap,
//   withScriptjs,
//   Marker,
// } from "react-google-maps";

import { Image } from "semantic-ui-react";
interface IEpycoHomeProps {}

interface IEpycoHomeState {
  rotate: boolean;
  scrollPositionY: any;
  bottomOverlay: boolean;
}

type Props = IEpycoHomeProps;

class EpycoNosotros extends React.Component<Props, IEpycoHomeState> {
  constructor(props: Props) {
    super(props);

    this.state = {
      rotate: false,
      scrollPositionY: 0,
      bottomOverlay: false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollHandler);
  }

  scrollHandler = () => {
    let scroll = window.scrollY;
    if (scroll > 600) {
      this.setState({ rotate: true });
    } else {
      this.setState({ rotate: false });
    }
    this.setState({
      scrollPositionY: scroll,
    });
  };

  renderBanner() {
    return (
      <div className="red-banner">
        <div className="red-banner-caption">
          <img src={BasicLogo} className="home-basic-logo" alt="Logo" />
        </div>
      </div>
    );
  }

  renderBootstrapCarousel() {
    // return (
    //   <div>
    //     {/* <h1> TIENDAS </h1> */}
    //     <Carousel>
    //       <Carousel.Item>
    //         <Link to="/tiendas/Camelot">
    //           <div>
    //             <img src={Grocery} alt="Camelot" className="carousel-img" />
    //             <Carousel.Caption>
    //               <div className="carousel-caption-div">
    //                 <p className="carousel-caption-text"> CAMELOT</p>
    //               </div>
    //             </Carousel.Caption>
    //           </div>
    //         </Link>
    //       </Carousel.Item>
    //       <Carousel.Item>
    //         <Link to="/tiendas/Music">
    //           <div>
    //             <img src={Music} alt="Music" className="carousel-img" />
    //             <Carousel.Caption> MUSIC </Carousel.Caption>
    //           </div>
    //         </Link>
    //       </Carousel.Item>
    //     </Carousel>
    //   </div>
    // );
  }

  renderRightImageSegment(image: any) {
    return (
      <div className="ui vertical stripe segment">
        <div className="ui middle aligned stackable grid container">
          <div className="row">
            <div className="eight wide column">
              <h3 className="ui header">
                Páginas Web Para Empresas y Negocios.
              </h3>
              <p>
                Desarrollamos páginas web personalizadas para empresas y
                negocios interesados en venta online, proveemos plataforma,
                hosting, dominio y despliegue.
              </p>
              <h3 className="ui header">Cátalogo y Venta en Línea</h3>
              <p>
                ¡A través de nuestra plataforma podrás ofrecer tus productos y
                servicios a una mayor cantidad de clientes!
              </p>
            </div>
            <div className="six wide right floated column">
              <img
                alt="right"
                src={image}
                className="ui large bordered rounded image"
              />
            </div>
          </div>
          <br />
          {/* <div className="row">
            <div className="center aligned column">
              <Link to="/stores" className="ui huge button">
                {" "}
                Tiendas{" "}
              </Link>
            </div>
          </div> */}
        </div>
      </div>
    );
  }

  renderLeftImageSegment(image: any) {
    return (
      <div className="ui vertical stripe segment">
        <div className="ui middle aligned stackable grid container">
          <div className="row">
            <div className="six wide column">
              <img
                alt="left"
                src={image}
                className="ui large bordered rounded image"
              />
            </div>
            <div className="eight wide right floated column">
              <h3 className="ui header">
                Expertos Calificados en Diseño en Paint{" "}
              </h3>
              <p>
                ¡Cuando me dijeron que los diseños fueron desarrollados en Paint
                no pude creerlo! Presentan una calidad que deslumbra y salta a
                la vista.
              </p>
              <h3 className="ui header">Rompemos Paradigmas Visuales</h3>
              <p>
                Nuestros diseños en Paint son únicos y originales. ¡Inician
                conversaciones y discusiones por sí solos!.
              </p>
            </div>
          </div>
          <br />
          {/* <div className="row">
            <div className="center aligned column">
              <a className="ui huge button">Check Them Out</a>
            </div>
          </div> */}
        </div>
      </div>
    );
  }

  renderBreak(image: any) {
    return (
      <div>
        <div className="ui vertical stripe quote segment">
          <div className="ui equal width stackable internally celled grid">
            <div className="center aligned row">
              <div className="column">
                <h3>
                  "No sé si es que llevan o no llevan tilde en el nombre."
                </h3>
                <p>
                  <img
                    alt="mini"
                    src={MiniRaboot}
                    className="ui avatar image"
                  />{" "}
                  <b> Pepe </b> Ciudadano Preocupado.
                </p>
              </div>
              <div className="column">
                <h3>"¡Sus diseños en Paint son otra cosa!"</h3>
                <p>Nuestros clientes mencionan.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="ui horizontal inverted divider" />
      </div>
    );
  }

  renderCenteredSegment() {
    return (
      <div className="ui vertical stripe segment">
        <div className="ui text container">
          <h3 className="ui header">Breaking The Grid, Grabs Your Attention</h3>
          <p>
            Instead of focusing on content creation and hard work, we have
            learned how to master the art of doing nothing by providing massive
            amounts of whitespace and generic content that can seem massive,
            monolithic and worth your attention.
          </p>
          <a className="ui large button">Read More</a>
          <h4 className="ui horizontal header divider">
            <a href="#">Case Studies</a>
          </h4>
          <h3 className="ui header">Did We Tell You About Our Bananas?</h3>
          <p>
            Yes I know you probably disregarded the earlier boasts as
            non-sequitur filler content, but its really true. It took years of
            gene splicing and combinatory DNA research, but our bananas can
            really dance.
          </p>
          <a className="ui large button">I'm Still Quite Interested</a>
        </div>
      </div>
    );
  }
  renderMisionVision() {
    return (
      <div
        className="ui vertical stripe segment inverted text-center background-black"
        style={{ paddingTop: "2vh" }}
      >
        {" "}
        {/* inverted */}
        {/* <h4 className="ui horizontal header divider"> o </h4> */}
        <div className="ui middle aligned stackable grid container">
          <div className="row">
            <div className="left floated seven wide column">
              <h3 className="ui header" style={{ color: "white" }}>
                {" "}
                MISIÓN{" "}
              </h3>
              <p>
                Contribuir con la ciudad y la comunidad a través del desarrollo
                de proyectos de edificación vanguardistas pensando en el ser
                humano y la sostenibilidad, trabajando en un entorno
                colaborativo que genere un valor compartido y así lograr una
                mejora continua para satisfacer más allá de las necesidades del
                cliente.
              </p>
            </div>
            <div className="right floated seven wide column">
              <h3 className="ui header" style={{ color: "white" }}>
                {" "}
                VISIÓN{" "}
              </h3>
              <p>
                Consolidarnos como una compañía vanguardista distinguida del
                Perú llegando a tener presencia fuera del país, siendo
                reconocidos por calidad como confiabilidad, tanto en el ámbito
                proyectual como en el inmobiliario.
              </p>
            </div>
          </div>
        </div>
        <div id="servicios" />
        <br />
        <br />
        <br />
        <br />
      </div>
    );
  }
  renderBasicInfo() {
    return (
      <div
        id="nosotros_section"
        className="ui vertical stripe inverted background-black segment "
      >
        {/* <br /> */}
        {/* <br /><br /> */}
        <div className="ui container text-center">
          {/* <hr/>  */}
          <h1
            className="ui header white"
            style={{ margin: "margin: 15px 0px 15px 0px", fontSize: "2.5rem", fontFamily: 'Gotham'}}
          >
            EPYCO Inmobiliaria y Construcción
          </h1>
          {/* <hr/> */}
          {/* <br /> */}
          <br />
          {/* <br /> */}
          <p style={{ textAlign: "justify" }}>
            Somos una empresa dedicada a la elaboración de proyectos de
            construcción que cuenta con un equipo humano que involucra a
            especialistas de las ramas de arquitectura e ingeniería, los cuales
            trabajan de manera conjunta y coordinada, con el objetivo de generar
            un alto valor al momento de hacer realidad los sueños de nuestros
            clientes. Así mismo, nos especializamos en el corretaje de bienes
            raíces (venta y alquiler), acompañando a nuestros clientes en la
            oferta o adquisición de un bien inmueble; asesorándolos desde el
            ámbito legal y tributario, hasta la reparación y remodelación de las
            edificaciones.
          </p>
          {/* <p>
            ¡Si estás interesado en nuestra historia revisa nuestra versión
            detallada!
          </p>{" "}
          <br /> */}
          {/* <div className="text-center">
            <a className="ui large secondary button"> Sobre Nosotros </a>
          </div> */}
        </div>
      </div>
    );
  }
  renderSingleBranch(image: any, alt: string, name: string) {
    return (
      <div className="column text-center">
        <img className="portrait-main-branch" src={image} alt={alt} />
        <h3 className="text-center"> {name} </h3>
        {/* <p>
            <b> {title} </b>
          </p> */}
        {/* <p> {description} </p> */}
        <br />
        <br />
      </div>
    );
  }
  renderBranches() {
    return (
      <div
        className="ui vertical inverted stripe segment background-black"
        style={{
          paddingTop: "0",
          paddingBottom: "2vh",
          width: "100vw",
        }}
      >
        <div
          className="ui three column stackable middle aligned grid"
          style={{ padding: "0% 8% 0% 8%" }}
        >
          {this.renderSingleBranch(BR1, "INMOBILIARIA", "INMOBILIARIA")}
          {this.renderSingleBranch(BR2, "DISEÑO", "DISEÑO")}
          {this.renderSingleBranch(BR3, "CONSTRUCCIÓN", "CONSTRUCCIÓN")}
        </div>
        <div id="proyectos" />
        <br />
      </div>
    );
  }

  renderProjectImage(image: any, alt: string, description: string) {
    return (
      <div className="container-hover-image">
        <img
          className="ui medium rounded home-project-image"
          src={image}
          alt={alt}
        />
        <div className="hover-text">
          <h3 className="project-main-font"> {alt} </h3>
          <p> {description} </p>
        </div>
      </div>
    );
  }

  renderProjectDisplay() {
    return (
      <div className="ui vertical inverted stripe segment padding-5em background-black">
        <div className="ui text container centered text-center">
          <hr />
          <h1
            className="ui header white"
            style={{ margin: "margin: 15px 0px 15px 0px" }}
          >
            {" "}
            PROYECTOS{" "}
          </h1>
          <hr />
          <br />
          <br />
          <br />
        </div>

        <div
          className="ui three column stackable grid margin-0"
          // style={{ padding: "0% 17% 0% 17%" }}
          style={{ width: "100vw", padding: "0% 6%" }}
        >
          <div className="column right aligned">
            {this.renderProjectImage(C2, "CASA GM", "Residencial")}
          </div>
          <div className="column text-center">
            {this.renderProjectImage(C1, "HOTEL CP", "Comercial")}
          </div>
          <div className="column left">
            {this.renderProjectImage(C3, "CASA AV", "Residencial")}
          </div>
          {/* <div className="column text-center">
            {this.renderProjectImage(B4, "B4")}
          </div>
          <div className="column text-center">
            {this.renderProjectImage(B5, "B5")}
          </div>
          <div className="column text-center">
            {this.renderProjectImage(B6, "B6")}
          </div>
          <div className="column text-center">
            {this.renderProjectImage(B7, "B7")}
          </div>
          <div className="column text-center">
            {this.renderProjectImage(B8, "B8")}
          </div>
          <div className="column text-center">
            {this.renderProjectImage(B9, "B9")}
          </div> */}
        </div>
        {/* <br /> */}
        {/* <br /> */}
        {/* <br /> */}
      </div>
    );
  }
  renderMainTeamMember(
    image: any,
    alt: string,
    name: string,
    title: string,
    description: string
  ) {
    return (
      <div className="column text-center">
        <img className="portrait-main-image" src={image} alt={alt} />
        <h3> {name} </h3>
        <p>
          <b> {title} </b>
        </p>
        {/* <p> {description} </p> */}
        <br />
        <br />
      </div>
    );
  }
  renderTeamMember(
    image: any,
    alt: string,
    name: string,
    title: string,
    description: string
  ) {
    return (
      <div className="column text-center">
        <img className="portrait-image" src={image} alt={alt} />

        <h3> {name} </h3>
        <p>
          <b> {title} </b>
        </p>
        {/* <p> {description} </p> */}
        <br />
        <br />
      </div>
    );
  }
  renderTeamSegment() {
    return (
      <div> TEMP </div>
      // <div className="ui vertical inverted stripe segment padding-5em">
      //   {" "}
      //   {/* padding-5em */}
      //   <div className="ui text container centered text-center">
      //     <h1 className="ui heade white"> Nuestro Equipo </h1>
      //     <br />
      //     <br />
      //   </div>
      //   <div
      //     className="ui two column stackable grid"
      //     style={{ padding: "0% 13% 0% 13%" }}
      //   >
      //     {this.renderMainTeamMember(
      //       T1,
      //       "T1",
      //       "Nombre",
      //       "Fundador",
      //       "Pequeña descripción general."
      //     )}
      //     {this.renderMainTeamMember(
      //       T2,
      //       "T1",
      //       "Nombre",
      //       "Fundador",
      //       "Pequeña descripción general."
      //     )}
      //   </div>
      //   <div className="ui three column stackable grid">
      //     {this.renderTeamMember(
      //       T3,
      //       "T3",
      //       "Nombre",
      //       "Cargo",
      //       "Pequeña descripción general."
      //     )}
      //     {this.renderTeamMember(
      //       T4,
      //       "T4",
      //       "Nombre",
      //       "Cargo",
      //       "Pequeña descripción general."
      //     )}
      //     {this.renderTeamMember(
      //       T5,
      //       "T5",
      //       "Nombre",
      //       "Cargo",
      //       "Pequeña descripción general."
      //     )}
      //     {this.renderTeamMember(
      //       T4,
      //       "T4",
      //       "Nombre",
      //       "Cargo",
      //       "Pequeña descripción general."
      //     )}
      //     {this.renderTeamMember(
      //       T5,
      //       "T5",
      //       "Nombre",
      //       "Cargo",
      //       "Pequeña descripción general."
      //     )}
      //     {this.renderTeamMember(
      //       T3,
      //       "T6",
      //       "Nombre",
      //       "Cargo",
      //       "Pequeña descripción general."
      //     )}
      //   </div>
      // </div>
    );
  }
  renderSocials() {
    return (
      <div
        id="socials"
        className="ui vertical stripe inverted segment background-black"
      >
        {" "}
        {/* inverted */}
        <div className="ui equal width stackable internally grid">
          <div className="center aligned row">
            <div className="column">
              <h3>¿Interesado en compra, venta o alquiler?</h3>
              <p> ¡Contáctanos para ver las mejores opciones! </p>
              <p>
                {" "}
                ¡Te brindamos asesoriamiento legal, tributario y constructivo!{" "}
              </p>
              {/* <div className="text-center">
                <a className="ui large secondary button"> Inmuebles </a>
              </div> */}
            </div>
            {/* <div className="ui vertical inverted divider" /> */}
            <div className="column">
              <h3> ¡Encuéntranos en nuestras redes sociales! </h3>
              <p>
                <a href="https://www.facebook.com/EPYCO.Inmobiliaria.Construccion">
                  <i className="facebook icon big white cursor-pointer" />
                </a>
                <a href="https://www.instagram.com/epycoinmobiliariayconstruccion/">
                  <i className="instagram icon big white cursor-pointer" />
                </a>
                <a href="https://www.pinterest.com/EPYCOinmobiliariayconstruccion/">
                  <i className="pinterest icon white big cursor-pointer" />
                </a>
                <a href="https://twitter.com/EPYCO_IyC">
                  <i className="twitter icon white big cursor-pointer" />
                </a>
                {/* <a href="https://api.whatsapp.com/send/?phone=51985815367">
                  <i className="whatsapp icon white big cursor-pointer" />
                </a> */}

                <a href="https://www.linkedin.com/company/epyco-inmobiliaria-y-construccion">
                  <i className="linkedin icon white big cursor-pointer" />
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderOverlay(rotate: boolean) {
    return (
      <div className="overlay">
        <div className="overlay-content">
          <div
            className="ui seven column stackable middle aligned grid"
            style={{ padding: "0% 3%", color: "white" }}
          >
            <div className="column left aligned">
              {!rotate && <img src={EW1} className="header-logo" alt="logo" />}
              {rotate && (
                <a href="#home">
                  <img src={EW1} className="header-logo-rotated" alt="logo" />
                </a>
              )}
            </div>
            <div className="column right aligned text-center">
              <a className="white-link" href="#nosotros_section">
                <h3> NOSOTROS </h3>{" "}
              </a>
            </div>
            <div className="column right aligned text-center">
              <a className="white-link" href="#servicios">
                <h3> SERVICIOS </h3>
              </a>
            </div>
            <div className="column right aligned text-center">
              <a className="white-link" href="#proyectos">
                <h3> PROYECTOS </h3>
              </a>
            </div>
            <div className="column right aligned text-center">
              <a className="white-link" href="#socials">
                <h3> INMOBILIARIA </h3>
              </a>
            </div>
            <div className="column aligned text-center">
              <a className="white-link" href="#socials">
                <h3> BLOG </h3>
              </a>
            </div>
            <div className="column left aligned text-center">
              <a className="white-link" href="#footer">
                <h3> CONTÁCTANOS </h3>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderBottomOverlay() {
    return (
      <div className="bottom-overlay">
        <div className="bottom-overlay-icon">
          <a href="https://api.whatsapp.com/send/?phone=51985815367">
            <i className="whatsapp icon white big cursor-pointer" />
          </a>
        </div>
        <div className="bottom-overlay-icon">
          <a href="https://api.whatsapp.com/send/?phone=51985815367">
            <i className="facebook messenger white icon  big cursor-pointer" />
          </a>
        </div>
        <div className="bottom-overlay-icon">
          <a href="https://api.whatsapp.com/send/?phone=51985815367">
            <i className="phone  icon white big cursor-pointer" />
          </a>
        </div>
        <div className="bottom-overlay-content">
          <a href="https://api.whatsapp.com/send/?phone=51985815367">
            <i className="comment alternate outline  icon green  huge cursor-pointer" />
          </a>
        </div>
      </div>
    );
  }

  render() {
    const { scrollPositionY, rotate } = this.state;

    console.log("Scroll: ", scrollPositionY, rotate);
    return (
      <div>
        {/* <HomeHeader /> */}
        {/* {this.renderCarousel()} */}
        {/* {this.renderFullCover()} */}
        {/* {this.renderModule()} */}
        {/* {this.renderMultiCarousel()} */}
        {/* {this.renderGoogleMaps()} */}
        {/* <GoogleMap /> */}
        {/* {this.renderRightImageSegment(HouseDesign)}
        {this.renderBreak(MiniRaboot)}
        {this.renderLeftImageSegment(Something03)} */}

        {/* {this.renderHome()} */}

        {/* EPICO */}
        <StaticHeader />
        <div id="home" />
        {/* <EpycoCarousel /> */}
        {this.renderBanner()}
        {this.renderBasicInfo()}

        {this.renderMisionVision()}

        {/* <SpringDemo /> */}
        {/* <Keyframes /> */}
        

        {/* {this.renderBranches()} */}
        {/* {this.renderProjectDisplay()} */}

        {this.renderTeamSegment()}
        {this.renderSocials()}
        {/* {this.renderBottomOverlay()} */}
        <BottomOverlay /> 
        {/* {this.renderEpycoFooter()} */}
        <Footer />
        {/* EPICO */}
        {/* rgb 161,44,21 */}
        <MessengerCustomerChat
            pageId="105531951434413"
            appId="1869393053208911"
            // htmlRef="<REF_STRING>"
            />
      </div>
    );
  }
}

export default EpycoNosotros;

import React from "react";
import { Link } from "react-router-dom";
import GoogleAuth from "./GoogleAuth";
import "../css/Header.css";
import LoginModal from "./LoginModal";
import history from "../history";

import Default from "../images/Default.png";

import { Auth } from "../store/auth/types";
import { AppState } from "../store";
import { ThunkDispatch } from "redux-thunk";
import { AppActions } from "../store/types";
import { bindActionCreators } from "redux";
import { startSignOut, startRedeemToken } from "../store/auth/actions";
import { connect } from "react-redux";
import { ShoppingCart } from "../store/shoppingCart/types";
import { StoreModel } from "../models";
import { startGetShoppingCart } from "../store/shoppingCart/actions";

interface IHeaderProps {
  match?: any;
}

interface IHeaderState {
  isModalOpen: boolean;
  isRegister: boolean;
  uncover: boolean;
  showCart: boolean;
  storeName: string;
}

type Props = IHeaderProps & LinkStateProps & LinkDispatchProps;
class ContentHeader extends React.Component<Props, IHeaderState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isModalOpen: false,
      isRegister: false,
      uncover: false,
      showCart: false,
      storeName: "",
    };

    this.renderModal = this.renderModal.bind(this);
    this.openSignInModal = this.openSignInModal.bind(this);
    this.openRegisterModal = this.openRegisterModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleUncover = this.toggleUncover.bind(this);
    this.toggleCart = this.toggleCart.bind(this);
    this.renderRightMenu = this.renderRightMenu.bind(this);
    this.redirectToCheckOut = this.redirectToCheckOut.bind(this);
  }

  async componentDidMount() {
    // console.log('P P', this.props)
    const storeName = this.props.match.params.store;
    this.setState({ storeName });
    window.scrollTo(0, 0);
    await this.checkLocalToken();
    await this.checkLocalCart();
  }

  toggleUncover() {
    const { uncover } = this.state;
    this.setState({
      uncover: !uncover,
    });
  }

  toggleCart() {
    const { showCart } = this.state;
    this.setState({
      showCart: !showCart,
    });
  }

  redirectToCheckOut() {
    const { storeName } = this.state;
    history.push(`/tiendas/${storeName}/checkout`);
  }

  renderLeftMenu() {
    const { uncover } = this.state;
    const name = uncover
      ? "ui vertical inverted sidebar menu left uncover visible"
      : "ui vertical inverted sidebar menu";
    return (
      <div className={name}>
        <a className="active item"> Enlineate </a>
        <Link to="/products/" className="item">
          Productos
        </Link>
        {/* <a className="item"> Iniciar Sesión </a>
      <a className="item"> Registrarse </a>
      <a className="item"> Cerrar Sessión </a> */}
        <a className="big item" onClick={this.toggleUncover}>
          <i className="arrow alternate circle left icon large" /> Regresar
        </a>
      </div>
    );
  }
  renderRightMenu() {
    const { showCart } = this.state;
    const { shoppingCart } = this.props;
    const name = showCart
      ? "ui vertical sidebar menu right uncover visible"
      : "ui vertical sidebar menu";
    if (!shoppingCart) {
      return <div> Nop </div>;
    }
    return (
      <div className={name}>
        <div className="text-center side-shopping-cart-title">
          <h3 onClick={this.toggleCart} className="cart-side-h3">
            <i className="shopping cart icon" />
            Carrito
          </h3>
          {shoppingCart.loading && (
            <div className="ui active dimmer">
              <div className="ui huge text loader"> Procesando </div>
            </div>
          )}
          {!shoppingCart.loading &&
            shoppingCart.success &&
            shoppingCart.shoppingCart.map((value) => {
              // console.log('Value', value, value.products, value.products.name)
              return (
                <div
                  className="ui item stackable two column middle aligned grid margin-l-r-0"
                  key={value.id}
                >
                  <div className="column image">
                    <img
                      alt={value.product.name}
                      className="cart-image"
                      src={Default}
                    />
                  </div>
                  <div className="column">
                    <h3> {value.product.name} </h3>
                    <p> Cantidad: {value.quantity} </p>
                    <p> Precio: {value.product.price.toFixed(2)} </p>
                    <p>
                      {" "}
                      Total: {(value.product.price * value.quantity).toFixed(2)}
                    </p>
                  </div>
                </div>
              );
            })}
          {!shoppingCart.loading && (
            <button
              onClick={this.redirectToCheckOut}
              className="ui centered secondary button"
              style={{ marginBottom: "1em" }}
            >
              Checkout
            </button>
          )}
        </div>
      </div>
    );
  }

  async checkLocalToken() {
    const { auth, startRedeemToken } = this.props;
    const token = localStorage.getItem("token");
    if (token) {
      if (!auth.token) {
        await startRedeemToken(token);
        await this.checkLocalCart();
      }
    }
  }
  async checkLocalCart() {
    const { shoppingCart, startGetShoppingCart } = this.props;
    const cart = localStorage.getItem("cart");
    // if(cart) {
    //   if (!shoppingCart.shoppingCart) {
    //     await startGetShoppingCart();
    //   }
    // }
    await startGetShoppingCart();
  }

  componentWillUnmount() {}

  // **** MODAL SECTION STARTS **** //
  closeModal() {
    const { isModalOpen } = this.state;
    if (isModalOpen) {
      this.setState({
        isModalOpen: false,
      });
    }
  }

  openSignInModal() {
    const { isModalOpen } = this.state;
    if (!isModalOpen) {
      this.setState({
        isModalOpen: true,
        isRegister: false,
      });
    }
  }

  openRegisterModal() {
    const { isModalOpen } = this.state;
    if (!isModalOpen) {
      this.setState({
        isModalOpen: true,
        isRegister: true,
      });
    }
  }
  renderModal() {
    const { isModalOpen, isRegister } = this.state;
    return (
      <LoginModal
        isModalOpen={isModalOpen}
        closeModal={this.closeModal}
        isRegister={isRegister}
      />
    );
  }

  // **** MODAL SECTION ENDS **** //

  renderBasic() {
    return (
      <div>
        <div
          className="ui secondary pointing fixed menu"
          style={{ padding: "5px" }}
        >
          <Link to="/" className="item">
            Enlineate (Imagen Logo)
          </Link>
          <div className="right menu">
            <Link to="/" className="item">
              Landing Page
            </Link>
            <GoogleAuth />
            <div className="ui primary button">
              <i className="shop icon" style={{ margin: "0px" }} />
            </div>
          </div>
        </div>
      </div>
    );
  }
  renderMenu() {
    const { auth, startSignOut } = this.props;
    return (
      // hidden
      <div className="ui large top menu margin-0">
        <div className="ui container">
          <Link to="/" className="item">
            Enlineate
          </Link>
          {/* <Link to="/users" className="item">
            Usuarios
          </Link> */}
          {/* <Link to="/stores" className="item">
            Tiendas
          </Link> */}
          {/* <Link to="/products/" className="active item hidden-mobile">
            Productos
          </Link> */}
          {/* <Link to="/categories" className="item">
            Categorías
          </Link> */}
          {auth.isSignedIn ? (
            <div className="right menu">
              <div className="item hidden-mobile">
                <a className="ui blue image medium label">
                  {auth.user.email}
                  <div className="detail">{auth.user.role?.description}</div>
                </a>
              </div>
              <div className="item" onClick={startSignOut}>
                <a className="ui button"> Cerrar Sesión </a>
              </div>
              <div className="right item">
                <i className="shopping cart icon" onClick={this.toggleCart} />
              </div>
            </div>
          ) : (
            <div className="right menu">
              <div className="item" onClick={this.openSignInModal}>
                <a className="ui secondary button"> Iniciar Sesión </a>
              </div>
              <div className="item" onClick={this.openRegisterModal}>
                <a className="ui secondary button"> Registrarse </a>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
  render() {
    // console.log('Props: ', this.props)
    return (
      // fixed hidden
      <div className="">
        {/* {this.renderBasic()} */}
        {this.renderRightMenu()}
        {this.renderModal()}
        {this.renderMenu()}
        {/* {this.renderHomeHeader()} */}
      </div>
    );
  }
}

interface LinkStateProps {
  auth: Auth;
  shoppingCart: ShoppingCart;
}

interface LinkDispatchProps {
  startSignOut: () => void;
  startRedeemToken: (token: string) => void;
  startGetShoppingCart: () => void;
}

const mapStateToProps = (state: AppState, ownProps: IHeaderProps) => ({
  auth: state.auth,
  shoppingCart: state.shoppingCart,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IHeaderProps
): LinkDispatchProps => ({
  startSignOut: bindActionCreators(startSignOut, dispatch),
  startRedeemToken: bindActionCreators(startRedeemToken, dispatch),
  startGetShoppingCart: bindActionCreators(startGetShoppingCart, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ContentHeader);

import { UserModel } from "../../models";
import { SIGN_IN, SIGN_OUT, AuthActionTypes, Auth, REDEEM_TOKEN } from "./types";
import { AppState } from "..";
import { Dispatch } from "redux";
import { userService } from "../../service";
import { toast } from "react-toastify";

// Typescript infers that this function is returning SignInAction
export function signIn(auth: Auth): AuthActionTypes {
  return {
    type: SIGN_IN,
    payload: auth,
  };
}

export function signOut(): AuthActionTypes {
  return {
    type: SIGN_OUT,
  };
}

export function redeemToken(auth: Auth): AuthActionTypes {
  return {
    type: REDEEM_TOKEN,
    payload: auth,
  }
}

export const startSignIn = (userData: UserModel) => {
  return async (
    dispatch: Dispatch<AuthActionTypes>,
    getState: () => AppState
  ) => {
    // const userData = new UserModel();
    try {
      const signedInUser: Auth = await userService.signIn(userData);
      toast.info("Bienvenido a Enlineate.");
      localStorage.setItem('token', signedInUser.token);
      return dispatch(signIn(signedInUser));
    } catch (ex) {}
  };
};

export const startRedeemToken = (token: string) => {
  return async (
    dispatch: Dispatch<AuthActionTypes>,
    getState: () => AppState
  ) => {
    try{
      const signedInUser: Auth = await userService.redeemToken(token);
      // toast.info("Bienvenido a Enlineate. (TOKEN)");
      // localStorage.setItem('token', signedInUser.token);
      return dispatch(redeemToken(signedInUser));
    } catch (ex) {

    }
  }
}

export const startSignOut = () => {
  return (dispatch: Dispatch<AuthActionTypes>, getState: () => AppState) => {
    return dispatch(signOut());
  };
};

import { combineReducers, applyMiddleware, createStore } from "redux";
import { authReducer } from "./auth/reducer";
import { storesReducer } from "./store/reducer";
import { categoryReducer } from "./category/reducer";
import { productReducer } from "./product/reducer";
import { userReducer } from "./user/reducer";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { AppActions } from "./types";
import { shoppingCartReducer } from "./shoppingCart/reducer";


export const rootReducer = combineReducers({
  auth: authReducer,
  stores: storesReducer,
  category: categoryReducer,
  product: productReducer,
  user: userReducer,
  shoppingCart: shoppingCartReducer,
});

export type AppState = ReturnType<typeof rootReducer>;

export const store = createStore(
  rootReducer,
  applyMiddleware(thunk as ThunkMiddleware<AppState, AppActions>)
);

// export default function configureStore() {
//     const middlewares = [thunkMiddleware];
//     const middlewareEnhancer = applyMiddleware(...middlewares);

//     const store = createStore(
//         rootReducer,
//         composeWithDevTools(middlewareEnhancer)
//     );

//     return store;
// }

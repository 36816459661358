import { UserModel } from '../../models';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_OUT = 'SIGN_OUT';
export const REDEEM_TOKEN = 'REDEEM_TOKEN';

export interface Auth {
    user: UserModel;
    isSignedIn: boolean;
    token: string;
}

interface SignInAction {
    type: typeof SIGN_IN,
    payload: Auth,
}

interface SignOutAction {
    type: typeof SIGN_OUT
}

interface RedeemTokenAction {
    type: typeof REDEEM_TOKEN,
    payload: Auth,
}

export type AuthActionTypes = SignInAction | SignOutAction | RedeemTokenAction
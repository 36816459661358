import React from "react";
import { bindActionCreators } from "redux";
import "../css/Header.css";
import { CONSTANTS } from "../constants";

import { connect } from "react-redux";
import { startSignIn, startSignOut } from "../store/auth/actions";
import { AppState } from "../store";
import { UserModel } from "../models";
import { Auth } from "../store/auth/types";
import { AppActions } from "../store/types";
import { ThunkDispatch } from "redux-thunk";

import { Dropdown, Card, Modal } from "react-bootstrap";

interface IGoogleAuthProps {
  isCardModalOpen?: boolean;
}

interface IGoogleAuthState {}

type Props = IGoogleAuthProps & LinkStateProps & LinkDispatchProps;

class GoogleAuth extends React.Component<Props, IGoogleAuthState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isCardModalOpen: false,
    };

    this.onSignInClick = this.onSignInClick.bind(this);
    this.onSignOutClick = this.onSignOutClick.bind(this);
    this.renderAuthButton = this.renderAuthButton.bind(this);
    this.onAuthChange = this.onAuthChange.bind(this);
    this.menuDropdown = this.menuDropdown.bind(this);
  }

  menuDropdown() {
    const { auth } = this.props;
    const { } = this.state;
    // if(isCardModalOpen) {
      return (
        <div>
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>
                <img src={auth?.user?.profileImage} alt="profile" />{" "}
                {auth?.user?.name}
              </Modal.Title>
            </Modal.Header>
          </Modal.Dialog>
          <Modal.Body>
            {/* <Card style={{ width: "18rem", textAlign: "center" }}>
              <Card.Img
                variant="top"
                src={auth?.user?.profileImage}
                style={{ borderRadius: "50%" }}
              />
              <Card.Body>
                <Card.Title> {auth?.user?.name} </Card.Title>
                <Card.Text> Lorem Ipsum Ip Dolorem</Card.Text>
                <button
                  className="ui google plus button"
                  onClick={this.onSignOutClick}
                >
                  <i className="google icon" />
                  Cerrar Sesión
                </button>
              </Card.Body>
            </Card> */}
          </Modal.Body>
          <Modal.Footer></Modal.Footer>
        </div>
    // } else {

    // }
    

      //     </Dropdown.Item>
      //   </Dropdown.Menu>
      // </Dropdown>
    );
  }

  renderAuthButton() {
    const { isSignedIn, auth } = this.props;
    if (isSignedIn === null) {
      return <div> {null} </div>;
    } else if (isSignedIn) {
      if (auth?.user?.profileImage === null) {
        return (
          <div>
            <button
              className="ui red google button"
              onClick={this.onSignOutClick}
            >
              <i className="google icon" />
              Cerrar Sesión
            </button>
          </div>
        );
      } else {
        return <div className="divImgAvatar"><img src={auth?.user?.profileImage} className="ui avatar image" alt="profile" /></div>;
      }
    } else {
      return (
        <div>
          <button className="ui red google button" onClick={this.onSignInClick}>
            <i className="google icon" />
            Iniciar Sesión
          </button>
        </div>
      );
    }
  }

  async onAuthChange(isSignedIn: boolean) {
    if (isSignedIn) {
      const auth = window.gapi.auth2.getAuthInstance();
      const basicProfile = await auth.currentUser.get().getBasicProfile();

      let userData = new UserModel();
      userData.email = basicProfile.getEmail();
      userData.profileImage = basicProfile.getImageUrl();
      userData.name = basicProfile.getGivenName();
      userData.surname = basicProfile.getFamilyName();


      this.props.startSignIn(userData);
    } else {
      this.props.startSignOut();
    }
  }

  async onSignInClick() {
    const auth = window.gapi.auth2.getAuthInstance();
    await auth.signIn();
  }

  async onSignOutClick() {
    const auth = window.gapi.auth2.getAuthInstance();
    await auth.signOut();
  }
  componentDidMount() {
    window.gapi.load("client:auth2", () => {
      window.gapi.client
        .init({
          clientId: CONSTANTS.gclientid,
          scope: "email",
        })
        .then(() => {
          const auth = window.gapi.auth2.getAuthInstance();
          const isSignedIn = auth.isSignedIn.get();
          this.setState({
            auth: auth,
            isSignedIn: isSignedIn,
          });
          this.onAuthChange(isSignedIn);
          auth.isSignedIn.listen(this.onAuthChange);
        });
    });
  }
  render() {
    return <div> {this.renderAuthButton()} </div>;
  }
}

interface LinkStateProps {
  auth?: Auth;
  isSignedIn: boolean;
}

interface LinkDispatchProps {
  startSignIn: (userData: UserModel) => void;
  startSignOut: () => void;
}

const mapStateToProps = (state: AppState, ownProps: IGoogleAuthProps) => ({
  isSignedIn: state.auth.isSignedIn,
  auth: state.auth,
});

const mapDispatchToProps = (
  dispatch: ThunkDispatch<any, any, AppActions>,
  ownProps: IGoogleAuthProps
): LinkDispatchProps => ({
  startSignIn: bindActionCreators(startSignIn, dispatch),
  startSignOut: bindActionCreators(startSignOut, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GoogleAuth);

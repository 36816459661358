import React from "react";

interface ICartSidebarProps {
    isVisible: boolean;
}

interface ICartSidebarState {}

type Props = ICartSidebarProps;

class CartSidebar extends React.Component<Props, ICartSidebarState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { isVisible } = this.props;
    return(
        <div className="ui right visible sidebar">
            Carrito de Compras DUDE
        </div>
    )
  }
}

export default CartSidebar;

import { UserService } from './UserService';
import { StoreService } from './StoreService';
import { CategoryService } from './CategoryService';
import { ProductService } from './ProductService';
import { ShoppingCartService } from './ShoppingCartService';

// Instance the class
const userService = new UserService();
const storeService = new StoreService();
const categoryService = new CategoryService();
const productService = new ProductService();
const shoppingCartService = new ShoppingCartService();

export {
    userService,
    storeService,
    categoryService,
    productService,
    shoppingCartService
}
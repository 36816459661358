import { UserModel } from "../../models";
import { Auth, SIGN_IN, SIGN_OUT, AuthActionTypes, REDEEM_TOKEN } from "./types";

const INITIAL_STATE: Auth = {
  isSignedIn: false,
  user: new UserModel(),
  token: '',
};

export function authReducer(
  state = INITIAL_STATE,
  action: AuthActionTypes
): Auth {
  switch (action.type) {
    case SIGN_IN:
      return {
        user: action.payload.user,
        token: action.payload.token,
        isSignedIn: true,
      };
    case SIGN_OUT:
      return {
        ...INITIAL_STATE
      };
    case REDEEM_TOKEN:
      return {
        user: action.payload.user,
        token: action.payload.token,
        isSignedIn: true,
      }
    default:
      return state;
  }
}
import React, { useState, useCallback } from "react";
import { useTransition, useSpring, animated, config } from "react-spring";
import MessengerCustomerChat from "react-messenger-customer-chat";
import "../css/spring.css";

export default function BottomOverlay() {
  const [show, set] = useState(false);
  const [messenger, setMessenger] = useState(false);
  const onClick = useCallback(() => set((state) => !state), []);
  const onMessengerClick = useCallback(
    () => setMessenger((state) => !state),
    []
  );
  const { transform, opacity, display } = useSpring({
    // from: {
    //   opacity: 0,
    //   height: 0,
    //   transform: `translateY(20px,0,0)`,
    //   display: "block",
    //   config: config.gentle,
    // },
    // to: {
      opacity: show ? 1 : 0,
      // // height: show ? "auto" : "0",
      transform: `translateY(${show ? "-20%" : "5%"})`,
      display: show ? "block" : "none",
      // config: { mass: 1, tension: 50, friction: 20 },
      config: config.gentle,
    // }
  });
  // const [bind, { height: viewHeight }] = useMeasure()
  // const { height, opacity, transform } = useSpring({
  //   from: { height: 0, opacity: 0, transform: 'translate3d(20px,0,0)' },
  //   to: { height: show ? viewHeight : 0, opacity: show ? 1 : 0, transform: `translate3d(${show ? 0 : 20}px,0,0)` }
  // })

  return (
    <animated.div className="bottom-overlay" >
      <div
      id="messenger"
        className="bottom-overlay-icon"
        style={{ opacity: show ? 1 : 0, transform }}
      >
        {/* <i className="facebook messenger blue icon  icon-three-em cursor-pointer" /> */}
        {/* {messenger && <div> */}
        <MessengerCustomerChat
          pageId="105531951434413"
          appId="1869393053208911"
          // htmlRef="messenger"
          language='es_ES'
          // // style={{ opacity: show ? 1 : 0, transform}}
        />
        {/* </div>} */}
      </div>
      <div className="bottom-overlay-icon" style={{ opacity: show ? 1 : 0, transform }}>
        <a href="https://api.whatsapp.com/send/?phone=51985815367"  target="_blank">
          <i className="whatsapp icon green icon-three-em cursor-pointer" />
        </a>
      </div>

      <div
        className="bottom-overlay-icon"
        style={{ opacity: show ? 1 : 0, transform }}
      >
        <i className="phone  icon blue icon-three-em cursor-pointer" />
      </div>

      <div className="bottom-overlay-content" onClick={onClick}>
        <i className="comment alternate outline  icon green  huge cursor-pointer" />
      </div>
    </animated.div>
  );
}

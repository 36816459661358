import React from "react";

interface ILoadingProps {
  loading: boolean;
  roundCorners: boolean;
}

interface ILoadingState {}

type Props = ILoadingProps;

class Loading extends React.Component<Props, ILoadingState> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const { loading, roundCorners } = this.props;
    if (loading) {
      if (roundCorners) {
        return (
          <div className="ui active dimmer round-border">
            <div className="ui huge text loader round-border">Procesando</div>
          </div>
        );
      } else {
        return (
          <div className="ui active dimmer">
            <div className="ui huge text loader">Procesando</div>
          </div>
        );
      }
    } else {
        return <div></div>
    }
  }
}

export default Loading;

import { ProductModel } from '../../models';

export const CREATE_PRODUCT = 'CREATE_PRODUCT';

export const GET_PRODUCTS = 'GET_PRODUCTS';
export const GET_PRODUCTS_SUCCESS = 'GET_PRODUCTS_SUCCESS';
export const GET_PRODUCTS_FAILURE = 'GET_PRODUCTS_FAILURE';

export const FIND_PRODUCTS = 'FIND_PRODUCTS';

export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'

export const UPLOAD_PRODUCT_IMAGE = 'UPLOAD_PRODUCT_IMAGE'
export const UPLOAD_PRODUCT_IMAGE_SUCCESS = 'UPLOAD_PRODUCT_IMAGE_SUCCESS'
export const UPLOAD_PRODUCT_IMAGE_FAILURE = 'UPLOAD_PRODUCT_IMAGE_FAILURE'

export const GET_PRODUCT_BY_ID = 'GET_PRODUCT_BY_ID'
export const GET_PRODUCT_BY_ID_SUCCESS = 'GET_PRODUCT_BY_ID_SUCCESS'
export const GET_PRODUCT_BY_ID_FAILURE = 'GET_PRODUCT_BY_ID_FAILURE'

export interface Products {
    products: ProductModel[];
    product: ProductModel;
    success: boolean;
    loading: boolean;
    image: string;
    imageSuccess: boolean;
    imageLoading: boolean;
}

interface CreateProductAction {
    type: typeof CREATE_PRODUCT,
    payload: ProductModel,
}

interface GetProducts {
    type: typeof GET_PRODUCTS,
}

interface GetProductsSuccess {
    type: typeof GET_PRODUCTS_SUCCESS,
    payload: ProductModel[],
}

interface GetProductsFailure {
    type: typeof GET_PRODUCTS_FAILURE,
}

interface FindProducts {
    type: typeof FIND_PRODUCTS,
    payload: ProductModel[],
}

interface UpdateProduct {
    type: typeof UPDATE_PRODUCT,
    payload: ProductModel,
}

interface UploadProductImage {
    type: typeof UPLOAD_PRODUCT_IMAGE,
}

interface UploadProductImageSuccess {
    type: typeof UPLOAD_PRODUCT_IMAGE_SUCCESS,
    payload: string,
}

interface UploadProductImageFailure {
    type: typeof UPLOAD_PRODUCT_IMAGE_FAILURE,
}

interface GetProductByID {
    type: typeof GET_PRODUCT_BY_ID,
}

interface GetProductByIDSuccess {
    type: typeof GET_PRODUCT_BY_ID_SUCCESS,
    payload: ProductModel,
}

interface GetProductByIDFailure { 
    type: typeof GET_PRODUCT_BY_ID_FAILURE,
}

export type ProductActionTypes = CreateProductAction | 
GetProducts | GetProductsSuccess | GetProductsFailure | 
FindProducts | UpdateProduct | 
UploadProductImage | UploadProductImageSuccess | UploadProductImageFailure | 
GetProductByID | GetProductByIDSuccess | GetProductByIDFailure;
import React, { useState, useCallback } from "react";
import { useTransition, useSpring, animated, config } from "react-spring";
import { useMediaQuery } from "react-responsive";

import "../css/spring.css";
import EW1 from "../images/EW1.png";

export default function TopOverlay(rot: any) {
  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-device-width: 1224px)",
  });
  const isLargerThanMobile = useMediaQuery({
    query: "(min-device-width: 761px)",
  });
  const isBigScreen = useMediaQuery({ query: "(min-device-width: 1600px)" });
  const isTableOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const isMobile = useMediaQuery({ query: "(max-width: 760px)" });
  const [rotate, setRotate] = useState(false);
  const [uncover, setUncover] = useState(false);
  const name = uncover
    ? "ui vertical inverted sidebar menu left uncover visible"
    : "ui vertical inverted sidebar menu";
  const { transform, filter } = useSpring({
    transform: `rotate(${rotate ? "90deg" : "0deg"})`,
    filter: `invert(${rot.rotate ? "1" : "0"})`,
  });

  // console.log('Rotate: ', rot.rotate, rotate);
  // if(rot.rotate != rotate) setRotate(rot);

  return (
    <div>
      {isLargerThanMobile && (
        <animated.div
          className="overlay"
          style={{
            backgroundColor: rot.rotate
              ? "rgba(50,50,50,0)"
              : "rgba(50,50,50,0.9)",
          }}
        >
          <div className="overlay-content">
            <div
              className="ui seven column stackable middle aligned grid"
              style={{ padding: "0% 3%", color: "white" }}
            >
              <div className="column left aligned">
                <a href="#inicio">
                  <img
                    src={EW1}
                    className="header-logo"
                    alt="logo"
                    style={{
                      transform: `rotate(${rot.rotate ? "-90deg" : "0deg"})`,
                      filter: `invert(${rot.rotate ? "1" : "0"})`,
                    }}
                  />
                </a>
              </div>
              <div
                className="column right aligned text-center"
                style={{ display: `${rot.rotate ? "none" : "block"}` }}
              >
                <a className="white-link" href="#nosotros_section">
                  <h4 style={{ fontWeight: "normal" }}> NOSOTROS </h4>{" "}
                </a>
              </div>
              <div
                className="column right aligned text-center"
                style={{ display: `${rot.rotate ? "none" : "block"}` }}
              >
                <a className="white-link" href="#nosotros_section">
                  <h4 style={{ fontWeight: "normal" }}> SERVICIOS </h4>
                </a>
              </div>
              <div
                className="column right aligned text-center"
                style={{ display: `${rot.rotate ? "none" : "block"}` }}
              >
                <a className="white-link" href="#proyectos">
                  <h4 style={{ fontWeight: "normal" }}> PROYECTOS </h4>
                </a>
              </div>
              <div
                className="column right aligned text-center"
                style={{ display: `${rot.rotate ? "none" : "block"}` }}
              >
                <a className="white-link" href="#socials">
                  <h4 style={{ fontWeight: "normal" }}> INMOBILIARIA </h4>
                </a>
              </div>
              <div
                className="column aligned text-center"
                style={{ display: `${rot.rotate ? "none" : "block"}` }}
              >
                <a className="white-link" href="#socials">
                  <h4 style={{ fontWeight: "normal" }}> BLOG </h4>
                </a>
              </div>
              <div
                className="column left aligned text-center"
                style={{ display: `${rot.rotate ? "none" : "block"}` }}
              >
                <a className="white-link" href="#footer">
                  <h4 style={{ fontWeight: "normal" }}> CONTÁCTANOS </h4>
                </a>
              </div>
            </div>
          </div>
        </animated.div>
      )}
      {isMobile && (
        <animated.div
          className="overlay"
          style={{
            backgroundColor: !rot.rotate && !uncover
              ? "rgba(50,50,50,0.95)"
              : "rgba(50,50,50,0)",
          }}
        >
          {!uncover && (
            <div className="overlay-content">
              <div
                className="column left aligned mobile-icon"
                onClick={() => setUncover((uncover) => !uncover)}
              >
                <img
                  src={EW1}
                  className="header-logo"
                  alt="logo"
                  style={{
                    transform: `rotate(${rot.rotate ? "-90deg" : "0deg"})`,
                    filter: `invert(${rot.rotate ? "1" : "0"})`,
                  }}
                />
              </div>
            </div>
          )}
          {uncover && (
            <div className={name}>
              <a className="item">
                <img
                  src={EW1}
                  className="header-logo"
                  alt="logo"
                  onClick={() => setUncover((uncover) => !uncover)}
                  style={{
                    transform: `rotate(${rot.rotate ? "-90deg" : "0deg"})`,
                    // // filter: `invert(${rot.rotate ? "1" : "0"})`,
                  }}
                />
              </a>
              <a
                className="big item"
                href="#inicio"
                onClick={() => setUncover((uncover) => !uncover)}
              >
                INICIO 
              </a>
              <a className="item" href="#nosotros_section" > NOSOTROS </a>
              <a className="item" href="#nosotros_section" > SERVICIOS </a>
              <a className="item" href="#proyectos" > PROYECTOS </a>
              <a className="item" href="#socials" > INMOBILIARIA </a>
              <a className="item" href="#socials" > BLOG </a>
              <a className="item" href="#footer" > CONTÁCTANOS </a>
            </div>
          )}
        </animated.div>
      )}
    </div>
  );
}

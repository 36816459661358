
import { axiosGET, axiosPOST, axiosFormDataPOST, axiosAuthPOST, axiosPUT } from './Setup';
import axios from 'axios';
import { CategoryModel, ProductModel } from '../models';
import { toast } from 'react-toastify';

const baseURL = 'products';

export class ProductService {

    public async findProducts(keyword: string): Promise<ProductModel[]> {
        let url = baseURL + '/find';
        let params = { name: keyword }
        const response = await axiosGET(url, params);
        const products: ProductModel[] = JSON.parse(response.data);
        return products;
    }

    public async getProducts(): Promise<ProductModel[]> {
        let url = baseURL;
        // const stores = new Array();
        const response = await axiosGET(url);
        const products: ProductModel[] = JSON.parse(response.data);
        return products;
    }

    public async getProductsByStoreName(keyword: string): Promise<ProductModel[]> {
        try {
            let url = baseURL + '/store';
            const params = { name: keyword }
            const response = await axiosGET(url, params);
            const products: ProductModel[] = JSON.parse(response.data);
            return products;
        } catch (ex) {
            toast.error(ex.message);
            throw new Error(ex.message);
        }
    }

    public async getProductByID(id: string): Promise<ProductModel> {
        let url = baseURL + '/id';
        let params = { id }
        try {
            const response = await axiosGET(url, params)
            const product: ProductModel = JSON.parse(response.data);
            return product;
        } catch (ex) {
            toast.error('ex.message')
            throw new Error(ex.message)
        }

    }

    public async uploadProductImage(image: File, url: string) {
        const response = await axiosPUT(url, image);
        console.log('Response Axios PUT', response);
        return response;
    }

    public async getPresignedUrl(imageName: string, token: string) {
        let url = baseURL + '/upload'
        console.log('Sayonara')
        const response = await axiosAuthPOST(url, imageName, token);
        console.log('Sayonara 2', response)
        return response;
    }

    public async createProduct(product: ProductModel, token: string) {
        let url = baseURL;

        const response = await axiosAuthPOST(url, product, token);

        return response;
    }

    public async updateProduct(product: ProductModel) {
        let url = baseURL + '/update';

        const response = await axiosPOST(url, product);

        return response;
    }
}
import { CategoryModel } from "../../models";
import { Categories, CREATE_CATEGORY, GET_CATEGORIES, FIND_CATEGORIES, CategoryActionTypes } from "./types";

const INITIAL_STATE: Categories = {
    categories: new Array(),
    category: new CategoryModel(),
    success: false,
}

export function categoryReducer(
    state = INITIAL_STATE,
    action: CategoryActionTypes
): Categories {
    switch (action.type) {
        case CREATE_CATEGORY:
            return {
                ...state,
                category: action.payload,
                success: true,
            };
        case GET_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
                success: true,
            };
        case FIND_CATEGORIES:
            return {
                ...state,
                categories: action.payload,
                success: true
            };
        default:
            return state;
    }
}